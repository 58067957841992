import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const today = new Date();

const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

const VisitasTable = () => {
    const {user} = useContext(UserContext);
    const [rango,setRango] = useState({start:firstDayMonth,end:lastDayMonth});
    const [visitas,setVisitas] = useState([]);
    const [loaded,setLoaded] = useState(false);
    const [sortBy, setSortBy] = useState({
        field:"",
        sortBy:"ASC"
    });
    const [permisos,setPermisos] = useState({
        visits_read:null,
        visits_update:null,
        visits_delete:null
    })

    useEffect(()=>{
       // setLoaded(false);
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                visits_read:checkPermiso("visits_read",user.permissions),
                visits_update:checkPermiso("visits_update",user.permissions),
                visits_delete:checkPermiso("visits_delete",user.permissions)
            }));
            //get visitas
            axios.post('api/visits_range',rango).then(resp=>{
                console.log("Visitasw", resp)
                setVisitas(resp.data);
            }).catch(e=>console.log(e))
        }
    },[user,rango])

    useEffect(()=>{
        (permisos.visits_read !== null) && setLoaded(true);
    },[permisos])

    const _handleSort = (field) =>{
        if(field !== sortBy.field){
            setSortBy({...sortBy,field:field,sortBy:"DESC"});
        }else{
            setSortBy({...sortBy,sortBy:sortBy.sortBy === "DESC"?"ASC":"DESC"})
        }
    }
   
    useEffect(()=>{
        _ordenarTabla();
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[sortBy])
 
     const _ordenarTabla = () => {
         //se crea una copia de visitas para que la funcion de sort no modifique el array original
         let ordenados = visitas.slice(0);
       
         if(!sortBy.field.includes(".")){
            if(sortBy.sortBy === "ASC"){
                ordenados.sort((a,b)=>{
                    if (a[sortBy.field] > b[sortBy.field]) {
                        return 1;
                      }
                      if (a[sortBy.field] < b[sortBy.field]) {
                        return -1;
                      }
                      return 0;
                })
            }else{
                ordenados.sort((a,b)=>{
                    if (a[sortBy.field] < b[sortBy.field]) {
                        return 1;
                      }
                      if (a[sortBy.field] > b[sortBy.field]) {
                        return -1;
                      }
                      return 0;
                })
            }
         }else{
         //Caso con objetos anidados
           let arrayField = sortBy.field.split(".");
                if(sortBy.sortBy === "ASC"){
                    ordenados.sort((a,b)=>{
                        if (a[arrayField[0]][arrayField[1]] > b[arrayField[0]][arrayField[1]]) {
                            return 1;
                        }
                        if (a[arrayField[0]][arrayField[1]] < b[arrayField[0]][arrayField[1]]) {
                            return -1;
                        }
                        return 0;
                    })
                }else{
                    ordenados.sort((a,b)=>{
                        if (a[arrayField[0]][arrayField[1]] < b[arrayField[0]][arrayField[1]]) {
                            return 1;
                        }
                        if (a[arrayField[0]][arrayField[1]] > b[arrayField[0]][arrayField[1]]) {
                            return -1;
                        }
                        return 0;
                    })
                }
         }
      
         setVisitas(ordenados)
     }
 

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'> 
                <div className='container-admin z-depth-1 row'>
                    {loaded ?
                        <>
                            <div className='row col s12 info-badge border-r-10 m-p-0 centrar-h'>
                                <div className='col s12'>
                                    <h6><span>Visitas del Mes</span> | <span>Fecha - {today.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</span> </h6>
                                </div>
                            </div>
                            {permisos.visits_read ?
                                <div className='m-p-0'>
                                    <div>
                                        <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                                            <div className='table-header row m-t-20 '>
                                                <div className='col s10 m2 centrar'> Doctor &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("doctor.apellidos")}}>unfold_more</i></div>
                                                <div className='col s10 m2 centrar'> Representante &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("representante")}}>unfold_more</i></div>
                                                <div className='col s10 m2 centrar'> Lugar / Horario</div>
                                                <div className='col s12 m1 centrar'> Fecha Visita <i className="material-icons" onClick={(e)=>{_handleSort("fecha_visita")}}>unfold_more</i></div>
                                                <div className='col s12 m1 centrar'> Hora <i className="material-icons" onClick={(e)=>{_handleSort("hora")}}>unfold_more</i></div>
                                                <div className='col s12 m1 centrar'> Causa</div>
                                                <div className='col s12 m2 centrar'> Observaciones</div>
                                                <div className='col s12 m1'> Acciones</div>
                                            </div>
                                            
                                            <div className='table-container'>
                                            {visitas.map((visita)=>
                                                <div className='row' key={visita.id}>
                                                    <div className='col s2 m2 centrar'>
                                                        <div>{visita?.doctor.apellidos} {visita?.doctor.nombre}</div>
                                                    </div>
                                                    <div className='col s2 m2 centrar'>
                                                        <div className='centrado'>{visita.representante}</div>
                                                    </div>
                                                    <div className='col s2 m2 centrar'>
                                                        <div className='centrado'>{visita.direccion} </div>
                                                    </div>
                                                    <div className='col s2 m1 centrar '>
                                                        <div className='centrado'>{visita.fecha_visita}</div>
                                                    </div>
                                                    <div className='col s3 m1 centrar'>
                                                        <div className='centrado'>{visita.hora}</div>
                                                    </div>
                                                    <div className='col s3 m1 centrar'>
                                                        <div className='centrado'>
                                                            {visita.causa_visita}
                                                        </div>
                                                    </div>
                                                    <div className='col s3 m2 centrar'>
                                                    <div className='centrado'>{visita.observaciones.slice(0,25)} ...</div>
                                                    </div>
                                                    <div className='col s12 m1 movil-oculto'>
                                                        <div className='row centrado cont-textos'>
                                                        {permisos.visits_update &&
                                                            <Link to="1" style={{color:'green'}}
                                                            data-position="top"
                                                            data-tooltip="Editar"
                                                            className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                                <i className="material-icons m-p-0" style={{fontSize:'20px',}}>create</i>
                                                            </Link>
                                                        }
                                                            <Link to={`detail/1`} 
                                                            data-position="top"
                                                            data-tooltip="Ver Detalle"
                                                            className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                                                <i className="material-icons m-p-0" 
                                                                style={{fontSize:'20px',}}>content_paste</i>
                                                            </Link>
                                                            {permisos.visits_delete &&
                                                            <div className='col m6 m-p-0 centrar movil-100'>
                                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} >delete_forever</i>
                                                            </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            </div>    
                                            
                                        </div>
                                    </div>
                                </div>
                                :
                                <NoPermissions />
                            } 
                        </>
                        :
                        <Loader />
                    }
                </div>
            </div>
        </div>
    );
};

export default VisitasTable;