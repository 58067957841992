import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import axios from '../../../axios';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import Loader from '../../../Layout/Loaders/Loader';
import NoPermissions from '../../NoPermissions/NoPermissions';

const EditCupones = () => {
    const  {cuponId} = useParams();
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [cupon,setCupones] = useState({
        nombre:'',
        tipo:'',
        descuento:'',
        vigencia:'',
        activo:1,
    });

    const [loaded,setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permisos,setPermisos] = useState({
        coupons_create:null,
        coupons_read:null,
        coupons_update:null,
        coupons_delete:null
    });
    
    useEffect(()=>{
        if(user.user.id){
            getCoupon();
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                coupons_create:checkPermiso("coupons_create",user.user.permissions),
                coupons_read:checkPermiso("coupons_read",user.user.permissions),
                coupons_update:checkPermiso("coupons_update",user.user.permissions),
                coupons_delete:checkPermiso("coupons_delete",user.user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.coupons_create !==null) && setLoaded(true);
    },[permisos])

    const getCoupon = () =>{
        axios.get('api/coupons')
        .then((res)=>{
            console.log('coupons',res)
            setCupones(res.data)
        }).catch(e=>console.log(e))
    }


    useEffect(()=>{
        axios.get(`api/coupons/${cuponId}`).then((resp)=>{
            console.log('cuponss', resp);
            setCupones(resp.data)
        }).catch(e=>console.log(e))
    },[cuponId])

    const _editcupon = (e) =>{
        e.preventDefault();
        console.log("cupon update..",cupon)
        axios.post(`api/coupons/${cuponId}`,cupon).then(resp=>{
            console.log('cuponEtado', resp.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>cupon actualizado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/cupones")
            }, 3000);
        }).catch(e=>console.log(e))
    }


    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                {loaded ?
                    permisos.coupons_read?
                    <>
                        <div className='container-admin-container m-p-0'>
                            <div className='row col s12 info-badge border-r-10 m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important'}}>
                                <div className="col s12 m12 row m-0 ">
                                    <div className='col s12 m3 m-p-0' 
                                    style={{height:'45px', width:'44px', padding:0, margin:'0!important',}}>
                                        <img className='dentometric m-p-0' src='/images/cupones.png' alt='dento metric' 
                                        style={{height:'100%', width:'100%'}} />
                                    </div>
                                    <div className="col s12 m6 m-6 centar"> <br></br> Editar cupon </div> 
                                </div>
                            </div>
                        </div>
                        
                        <div style={{border:'solid red'}}> 
                            <form onSubmit={_editcupon}  className="row ancho100" id='general-form'>
                                <div className="col s12 m12 m-t-25">
                                    <div className="input-text">
                                        <label htmlFor="name"> Nombre </label>
                                        <input id="name" type="text" 
                                        value={cupon.nombre}
                                        onChange={(e)=>setCupones({...cupon,nombre:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="tipo">Tipo de Cupon</label>
                                        <select className='browser-default custom-select' 
                                        value={cupon.tipo} 
                                        onChange={(e)=> setCupones({...cupon,tipo:e.target.value})}>
                                        <option value="">Seleccione un tipo</option>
                                            <option value={'Descuento %'}>Descuento %</option>
                                            <option value={'Cantidad'}>Cantidad</option>
                                        </select>
                                        
                                    </div>
                                </div>
                                <div className="col s12 m3 m-t-25">
                                    <div className="input-text">
                                        <label htmlFor="descuento"> Descuento </label>
                                        <input id="descuento" type="number" 
                                        value={cupon.descuento} 
                                        onChange={(e)=>setCupones({...cupon,descuento:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3">
                                    <div className="input-text">
                                        <label htmlFor="vigencia">Vigencia (Expira el día)</label>
                                        <input id="vigencia" type="date" 
                                        value={cupon.vigencia}
                                        onChange={(e)=>setCupones({...cupon,vigencia:e.target.value})}/>
                                    </div>
                                </div>
                                <div className="col s12 m3 centrar">
                                    <div className="input-text">
                                        <label htmlFor="vigencia"> Activo</label>          
                                        <div className="switch input-text">       
                                            <label>
                                                no
                                            <input id="boletin" type="checkbox" 
                                            checked={cupon.activo}
                                            value={cupon.activo}
                                            onChange={(e)=>setCupones({...cupon,activo:e.target.checked})}/>
                                            <span className="lever"></span> si
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col s12 m12 centrar-r"> 
                                    <button className="btn waves-effect waves-light" type="submit" name="action"> Guardar cupon
                                        <i className="material-icons right">save</i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        </>
                    : <NoPermissions/>
                :
                <div className='height-200 centrar'>
                    <Loader/>
                </div>
                }
                </div>
            </div>
        </>
    );
};

export default EditCupones;