import React,{useState} from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import M from 'materialize-css';
import { useNavigate } from 'react-router-dom';

const New = () => {

    const [sucursal,setSucursal] = useState({nombre_sucursal:"", domicilio:"", horario:"", horario_entregas:"", telefono1:"", telefono2:"", telefono3:""});
    const [validate,setValidate] = useState({nombre_sucursal:true, domicilio:true, horario:true, horario_entregas:true, telefono1:true});
    
    const navigate = useNavigate();

    const _saveSucursal = (e) =>{
        e.preventDefault();
        console.log("guardando...",sucursal)
       
       axios.post('api/sucursals',sucursal)
        .then(res=>{
            console.log("nuevo sucursal..",res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>sucursal creada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/sucursales")
            }, 5000);
        }).catch(e=>{
            console.log("error",e)
            if(e.message) {
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span> Llena los campos obligatorios </span>',classes:"error-notify"})
            }
            if(e.response.data.errors){
                let errors = e.response.data.errors;
                setValidate({
                    nombre_sucursal:(errors.nombre_sucursal)? false:true, 
                    domicilio:(errors.domicilio)? false:true,
                    horario:(errors.horario)? false:true,
                    horario_entregas:(errors.horario_entregas)? false:true,
                    telefono1:(errors.telefono1)? false:true,
                    telefono2:(errors.telefono2)? false:true,
                    telefono3:(errors.telefono3)? false:true,
                })
            }
        })   
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5 className='p-5'>Nueva Sucursal</h5>
                    <form onSubmit={_saveSucursal} className="row ancho100" id='general-form'>
                        
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="name">*Nombre</label>
                                <input id="name" type="text" className="validate" 
                                onChange={(e)=>setSucursal({...sucursal,nombre_sucursal:e.target.value})}/>
                                {!validate.nombre_sucursal &&  <span class="helper-text t-rojo">El nombre es obligatorio</span>}
                            </div>
                        </div>
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="domicilio">*Domicilio</label>
                                <textarea id="textarea1" className="materialize-textarea validate"
                                onChange={(e)=>setSucursal({...sucursal,domicilio:e.target.value})}>
                               </textarea>
                               {!validate.domicilio &&  <span class="helper-text t-rojo">El domicilio es obligatorio</span>}
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="horario">*Horario de Atención</label>
                                <input id="horario" type="text" className="validate" 
                                onChange={(e)=>setSucursal({...sucursal,horario:e.target.value})}/>
                                {!validate.horario &&  <span class="helper-text t-rojo">El horario de atencion es obligatorio</span>}
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="horario_entregas">*Horario de entrega</label>
                                <input id="horario_entregas" type="text" className="validate"
                                onChange={(e)=>setSucursal({...sucursal,horario_entregas:e.target.value})}/>
                                {!validate.horario_entregas &&  <span class="helper-text t-rojo">El horario de entrega es obligatorio</span>}
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="input-text">
                                <label htmlFor="telefono1">*Telefono</label>
                                <input id="telefono1" type="number" className="validate"
                                onChange={(e)=>setSucursal({...sucursal,telefono1:e.target.value})}/>
                                {!validate.telefono1 &&  <span class="helper-text t-rojo">El telefeno es obligatorio</span>}
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="input-text">
                                <label htmlFor="telefono2">Telefono</label>
                                <input id="telefono2" type="number" className="validate"
                                onChange={(e)=>setSucursal({...sucursal,telefono2:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="input-text">
                                <label htmlFor="telefono3">Telefono</label>
                                <input id="telefono3" type="number" className="validate"
                                onChange={(e)=>setSucursal({...sucursal,telefono3:e.target.value})}/>
                            </div>
                        </div>
                   
                        
                        <div className='col s12 p-t-35'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default New;