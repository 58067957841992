import React, { useContext, useEffect, useState } from 'react';
import '../MenuLeft/menuLeft.scss'
import M from 'materialize-css'
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import Spinner from '../../Layout/Loaders/Spinner';
import axios from '../../axios';

const MenuLeft = (props) => {
    const {colapsed, setColapsed, setWContainerAdmin, setWMenuLateral} = props;
    const {user,setUser,setAuth} = useContext(UserContext);
    const [routes,setRoutes] = useState([])
    const [loaded,setLoaded] = useState(false);
    const navigate = useNavigate();
    const [permisos,setPermisos] = useState({
        users_read:null,
        doctors_read:null,
        visits_read:null,
        employees_read:null,
        employee_vacations_history:null,
        appointment_models_list_read:null,
        appointments_list_read:null,
        products_read:null,
        categories_read:null,
        sucursals_read:null,
        doctor_specialties_read:null,
        doctor_institutions_read:null,
        deliveries_read:null,
        suppliers_read:null,
        employee_types_read:null,
        configs_countries_module:null,
        orders_read:null,
        orders_create:null,
        patient_read:null,
        coupons_read:null
    });


    useEffect(()=>{  

        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                users_read:checkPermiso("users_read",user.permissions),
                doctors_read:checkPermiso("doctors_read",user.permissions),
                visits_read:checkPermiso("visits_read",user.permissions),
                employees_read:checkPermiso("employees_read",user.permissions),
                employee_vacations_history:checkPermiso("employee_vacations_history",user.permissions),
                appointment_models_list_read:checkPermiso("appointment_models_list_read",user.permissions),
                appointments_list_read:checkPermiso("appointments_list_read",user.permissions),
                products_read:checkPermiso("products_read",user.permissions),
                categories_read:checkPermiso("categories_read",user.permissions),
                sucursals_read:checkPermiso("sucursals_read",user.permissions),
                doctor_specialties_read:checkPermiso("doctor_specialties_read",user.permissions),
                doctor_institutions_read:checkPermiso("doctor_institutions_read",user.permissions),
                deliveries_read:checkPermiso("deliveries_read",user.permissions),
                suppliers_read:checkPermiso("suppliers_read",user.permissions),
                employee_types_read:checkPermiso("employee_types_read",user.permissions),
                configs_countries_module:checkPermiso("configs_countries_module",user.permissions),
                orders_read:checkPermiso("orders_read",user.permissions),
                orders_create:checkPermiso("orders_create",user.permissions),
                patient_read:checkPermiso("patient_read",user.permissions),
                coupons_read:checkPermiso("coupons_read",user.permissions)
            }));
        }
    },[user])

   useEffect(()=>{
  
    if(permisos.users_read !== null){
        setRoutes([
            {
                path: "/usuarios",
                name: "Usuarios",
                icon: "group_add",
                iconColor:"#5e72e4",
                layout: "/admin",
                visible:(permisos.users_read),
                subRoutes:[
                    {
                        path: "/usuarios",
                        name: "Usuarios",
                        icon: "person_add",
                        iconColor:"#5e72e4",
                        visible:(permisos.users_read)
                    },
                    {
                        path: "/roles",
                        name: "Roles",
                        icon: "person_pin",
                        iconColor:"#5e72e4",
                        visible:(permisos.users_read)
                    }
                ],
            },
            {
                path: "/doctores",
                name: "Doctores",
                icon: "enhanced_encryption",
                iconColor:"#CC0000",
                layout: "/admin",
                visible:(permisos.doctors_read || permisos.visits_read),
                subRoutes:[
                    {
                        path: "/doctores",
                        name: "Doctores",
                        icon: "group",
                        iconColor:"#CC0000",
                        visible:(permisos.doctors_read)
                    },
                    {
                        path: "/visitas",
                        name: "Visitas Doctores",
                        icon: "drive_eta",
                        iconColor:"#CC0000",
                        visible:(permisos.visits_read)
                    },
                    {
                        path: "/calendario",
                        name: "Calendario visitas",
                        icon: "event_note",
                        iconColor:"#CC0000",
                        visible:(permisos.visits_read)
                    }
                ],
            },
            {
                path: "/empleados",
                name: "Empleados",
                icon: "assignment_ind",
                iconColor:"#11CDEF",
                layout: "/admin",
                visible:(permisos.employees_read || permisos.employee_vacations_history),
                subRoutes:[
                    {
                        path: "/empleados",
                        name: "Empleados",
                        icon: "group",
                        iconColor:"#11CDEF",
                        visible:(permisos.employees_read)
                    },
                    {
                        path: "/empleados/vacaciones",
                        name: "Vacaciones",
                        icon: "card_travel",
                        iconColor:"#11CDEF",
                        visible:(permisos.employee_vacations_history)
                    }
                ],
            },
            {
                path: "/agenda",
                name: "Agenda",
                icon: "book",
                iconColor:"#4CAF50 ",
                layout: "/admin",
                visible:(permisos.appointments_list_read || permisos.appointment_models_list_read),
                subRoutes:[
                    {
                        path: "/agenda",
                        name: "Agenda",
                        icon: "event_note",
                        iconColor:"#4CAF50 ",
                       visible:(permisos.appointments_list_read)
                    },
                    {
                        path: "/modelo-citas",
                        name: "Modelo de Citas",
                        icon: "library_books",
                        iconColor:"#4CAF50 ",
                        visible:(permisos.appointment_models_list_read)
                    },
                    {
                        path: "/veragenda",
                        name: "Ver Agenda",
                        icon: "event",
                        iconColor:"#4CAF50 ",
                        visible:(permisos.appointments_list_read)
                    },
                ],
            },
            {
                path: "/pedidos",
                name: "Pedidos",
                icon: "shopping_cart",
                iconColor:"#E3B778",
                layout: "/admin",
                visible:(permisos.orders_read||  permisos.orders_create || permisos.patient_read),
                subRoutes:[
                    {
                        path: "/pedidos",
                        name: "Pedidos Principal",
                        icon: "shopping_cart",
                        iconColor:"#E3B778",
                        visible:(permisos.orders_read)
                    },
                    {
                        path: "/pedidos-nuevo",
                        name: "Hacer Pedido",
                        icon: "add_shopping_cart",
                        iconColor:"#E3B778",
                        visible:(permisos.orders_create)
                    },
                    {
                        path: "/clientes",
                        name: "Clientes",
                        icon: "nature_people",
                        iconColor:"#E3B778",
                        visible:(permisos.patient_read)
                    },
                    {
                        path: "/cupones",
                        name: "Cupones",
                        icon: "new_releases",
                        iconColor:"#E3B778",
                        visible:(permisos.coupons_read)
                    }
                ],
            },
            {
                path: "/servicios",
                name: "Servicios",
                icon: "apps",
                iconColor:"#1565C0",
                layout: "/admin",
                visible:(permisos.products_read || permisos.categories_read),
                subRoutes:[
                    {
                        path: "/Servicios",
                        name: "Servicios",
                        icon: "flare",
                        iconColor:"#1565C0",
                        visible:(permisos.products_read)
                    },
                    {
                        path: "/categorias",
                        name: "Categorias",
                        icon: "collections_bookmark",
                        iconColor:"#1565C0",
                        visible:(permisos.categories_read)
                    },
                ],
            },
            {
                path: "/catalogos",
                name: "Catalogos",
                icon: "chrome_reader_mode",
                iconColor:"#FF6347",
                layout: "/admin",
                visible:(permisos.sucursals_read || permisos.doctor_specialties_read || permisos.doctor_institutions_read || permisos.deliveries_read || permisos.deliveries_read || permisos.employee_types_read),
                subRoutes:[
                    {
                        path: "/sucursales",
                        name: "Sucursales",
                        icon: "location_city",
                        iconColor:"#FF6347",
                        visible:(permisos.sucursals_read)
                    },
                    {
                        path: "/especialidades",
                        name: "Medicos Especialidad",
                        icon: "assignment",
                        iconColor:"#FF6347",
                        visible:(permisos.doctor_specialties_read)
                    },
                    {
                        path: "/instituciones",
                        name: "Instituciones",
                        icon: "location_city",
                        iconColor:"#FF6347",
                        visible:(permisos.doctor_institutions_read)
                    },
                    {
                        path: "/paqueteria",
                        name: "Paqueteria",
                        icon: "local_shipping",
                        iconColor:"#FF6347",
                        visible:(permisos.deliveries_read)
                    }, 
                    {
                        path: "/proveedores",
                        name: "Proveedores",
                        icon: "content_paste",
                        iconColor:"#FF6347",
                        visible:(permisos.deliveries_read)
                    },
                    {
                        path: "/tipoempleados",
                        name: "Tipo Empleados",
                        icon: "recent_actors",
                        iconColor:"#FF6347",
                        visible:(permisos.employee_types_read)
                    }
                ],
            },
            {
                path: "/configuracion",
                name: "Configuraciones",
                icon: "settings",
                iconColor:"#2DCE89",
                layout: "/admin",
                visible:(permisos.configs_countries_module),
                subRoutes:[
                    {
                        path: "/paises",
                        name: "Paises",
                        icon: "flag",
                        iconColor:"#2DCE89",
                        visible:(permisos.configs_countries_module)
                    }
                ],
            }
        ]);
    }
   },[permisos]);

   useEffect(()=>{ 
    M.AutoInit();  
    if(routes.length > 0){
        setLoaded(true)
    }
   },[routes])

    useEffect(()=>{
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems,{});
    },[])

    const changeWhith=()=>{
        if(!colapsed){
            setWMenuLateral('7%')
            setWContainerAdmin('93%')
            setColapsed(true)
        }
        else{
            setWMenuLateral('15%')
            setWContainerAdmin('85%')
            setColapsed(false)
        }
    }

    const _renderLogout = ()=>{
        return(
            <li className="no-padding">
                <ul className="m-p-0 collapsible collapsible-accordion">
                    <li>
                        <div className="collapsible-header a-menu" onClick={_logout}>
                            <i className="icon material-icons" style={{color:"#000000"}}>exit_to_app</i> &nbsp;&nbsp;
                            {!colapsed && <span className='nombre'>Cerrar Sesion</span>}
                        </div>
                    </li>
                </ul>
            </li>
        )
    }

    const _logout = () => {
        console.log("Cerrando sesion...")
        axios.delete('api/logout').then(resp=>{
            console.log("deslogear",resp);
            setUser({});
            setAuth(false);
            localStorage.removeItem("access_token");
            navigate("/")
        }).catch(e=>console.log("Error: ",e))
    }

    /**
     * 
     * @param {*} route ruta principal para renderizar el accordion parent
     * @param {*} subRoute subruta para renderizar el sub menu del acordion
     * @returns elemento del submenu
     */
    const renderSubMenu = (route,subRoute,i) =>{
        return (
            <li key={i}>
                <Link to={route.layout+subRoute.path} className='a-submenu modal-close waves-effect'>
                    <div>
                        <i className="material-icons" style={{color:subRoute.iconColor}}>{subRoute.icon}</i>
                        {!colapsed && <span>{subRoute.name}</span>}
                    </div>
                </Link>
            </li>
        )
    }

    return (
        <div className='m-p-0'>
            <div className='col s12 movil-visible'>
                <button className="waves-effect waves-light btn modal-trigger m-p-0 centrar"  data-target="menuMovil"
                style={{width:'36px',height:'36px', margin:'0!important'}}>
                    <i className="material-icons">menu</i>
                </button>
                <div id="menuMovil" className="modal" 
                style={{width:'100vw!important',height:'100vh!important'}}>
                    <div className="modal-content"  style={{border:'solid red 2px'}}>
                        <div className='centrar'>
                            <img className='dentometric' src='/images/logo.png' alt='dento metric' 
                            style={{height: !colapsed?'100px' : '40px' }} />
                            <a className="modal-close waves-effect btn-flat btn centrar btn-close-menu">
                                <i className="material-icons" style={{color:'#ffffff!important'}}>close</i>
                            </a>
                        </div>
                        <ul id="slide-out">
                            {routes.map((route,index)=>
                                route.visible&&
                                <li className="no-padding" key={index} style={{border:'solid red!important'}}>
                                    <ul className="m-p-0 collapsible collapsible-accordion">
                                        <li>
                                            <Link to={route.subRoutes.length===0?route.layout+route.path:"#"} 
                                            className={route.subRoutes.length===0 ? 'a-menu collapsible-header modal-close waves-effect' : 'a-menu collapsible-header'}>
                                                <i className="icon material-icons" style={{color:route.iconColor}}>{route.icon}</i>
                                                {!colapsed && <span className='nombre'>{route.name}</span>}
                                                {route.subRoutes.length>0&&<i className="flecha material-icons">arrow_drop_down</i>}
                                            </Link>
                                            {route.subRoutes.length>0&&
                                                <div className="m-p-0 collapsible-body" id='submenu'>
                                                    <ul>
                                                        {route.subRoutes.map((sub,i)=> 
                                                            renderSubMenu(route,sub,i)
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>


            <div className='movil-oculto'>
                <div className='menuLeft'>
                    <div className='icon-menu' style={{left:!colapsed?'16%' : '8%', border:'solid pink 2px!important'}}>
                        <a data-target="slide-out" className="sidenav-trigger" onClick={(e) => changeWhith()}
                        style={{width:'40px', height:'40px',}}>
                            {!colapsed? <i className="material-icons">menu</i> : <i className="material-icons">clear_all</i>}
                        </a>
                    </div>
                    <div className='centrar'>
                        <img className='dentometric' src='/images/logo.png' alt='dento metric' style={{height: !colapsed?'100px' : '40px' }} />
                    </div>
                    
                    <ul id="slide-out" className="sidenav2">
                        {routes.map((route,index)=>
                            route.visible&&
                                <li className="no-padding" key={index}>
                                    <ul className="m-p-0 collapsible collapsible-accordion">
                                        <li>
                                            <Link to={route.subRoutes.length===0?route.layout+route.path:"#"} className="a-menu collapsible-header">
                                                <i className="icon material-icons" style={{color:route.iconColor}}>{route.icon}</i> &nbsp;&nbsp;
                                                {!colapsed && <span className='nombre'>{route.name}</span>}
                                                {route.subRoutes.length>0&&<i className="flecha material-icons">arrow_drop_down</i>}
                                            </Link>
                                            {route.subRoutes.length>0&&
                                                <div className="m-p-0 collapsible-body" id='submenu'>
                                                    <ul>
                                                        {route.subRoutes.map((sub,i)=>
                                                            sub.visible &&
                                                            <li key={i}>
                                                                <Link to={route.layout+sub.path} className='a-submenu'>
                                                                    <div>
                                                                        <i className="material-icons" style={{color:sub.iconColor}}>{sub.icon}</i>
                                                                        {!colapsed && <span>{sub.name}</span>}
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                        </li>
                                    </ul>
                                </li>
                        )} 

                        {_renderLogout()}
                    </ul>
                </div> 
            </div>       
        </div>
    );
};

export default MenuLeft;