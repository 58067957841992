import React, { useState,useEffect } from 'react';
import M from 'materialize-css';
import axios from '../../../axios';
import { es } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const options ={
    dismissible:false,
    opacity:0.8,
    endingTop:'10%'
}

const ModalVacation = (props) => {
    const [nuevoFlag,setNuevoFlag] = useState(true);
    const [showDiasInput,setShowDiasInput] = useState(false);
    const [singleCalendario,setSingleCalendario] = useState(true);
    const [selectedDay,setSelectedDay] = useState('');
    const [days, setDays] = useState([])

    const [newVacation,setNewVacation] = useState(
        {
            employee_id:null,
            fechas:"",
            fecha_inicio:"",
            fecha_fin:"",
            dias_a_tomar:"",
            tipo_permiso:"",
            motivo:"",
            notas:"",
            medio_dia:"",
            sin_gose_sueldo:0,
            status_aprobacion:"Aprobadas"
    });


    useEffect(()=>{
        setNuevoFlag(props.solicitudId?false:true)
        setNewVacation({...newVacation,employee_id:props.empleadoID})
        var elems = document.querySelector('#modalvacaciones');
        M.Modal.init(elems,options);
        M.FormSelect.init(document.querySelector('#permiso-select'));
    },[props.empleadoID]);

    useEffect(()=>{
        if(selectedDay){
            let formatedDay = selectedDay.toISOString().split('T')[0];
            setNewVacation({...newVacation,fechas:formatedDay,fecha_inicio:formatedDay,fecha_fin:formatedDay});
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDay]);

    useEffect(()=>{
        if(days.length > 0){
            //ordenamos y guardamos en un arreglo temporal
            let tempArray = days.map((day)=>day.toISOString().split('T')[0]).sort();
            let tempStringDays = tempArray.toString();
            setNewVacation({...newVacation,fechas: tempStringDays,fecha_inicio:tempArray[0],fecha_fin:tempArray[tempArray.length-1],dias_a_tomar:tempArray.length});
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[days]);


    const closeModal = () =>{
        console.log("Cerrar modal",newVacation);
        setNewVacation({
            fechas:"",
            fecha_inicio:"",
            fecha_fin:"",
            dias_a_tomar:"",
            tipo_permiso:"na",
            motivo:"",
            notas:"",
            medio_dia:"",
            sin_gose_sueldo:false,
            status_aprobacion:"Aprobadas"
        });
     
        let instance = M.Modal.getInstance(document.querySelector('#modalvacaciones'));
        instance.close();
    }

    const onChangeValue = (event) => {
        let status = event.target.value;
        if(status === "1"){
            setSingleCalendario(true);
            setNewVacation({...newVacation,dias_a_tomar:status});
            setShowDiasInput(false);
        }else if(status === "0.5"){
            setSingleCalendario(true);
            setNewVacation({...newVacation,dias_a_tomar:status,medio_dia:true});
            setShowDiasInput(false);
        }
        if(status === '+1'){
         //   console.log("mas de uno")
            setSingleCalendario(false);
            setShowDiasInput(true)
        }
    }

    const _saveNewVacation = () =>{
        console.log("guardando vacaciones....",newVacation);
     
        axios.post('api/employees_vacations',newVacation).then((resp)=>{
            console.log(resp.data);
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>solicitud creada exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                console.log("las props",props)
                props._getVacacionesRequest(props.empleadoID,props.aniosTrabajados)
                closeModal();
            }, 3000);

        }).catch(e=>console.log("Error.",e))
    }

    const footer = () => {
        if(days && days.length > 0) {
            return (
                <p>Haz seleccionado {days.length} día(s).</p>
            );
        }else{
            return(
                <p>Por favor selecciona uno o más días.</p>
            );
        }
    }
  
    

    return (
        <div id="modalvacaciones" className="modal">
            <div className="modal-content">
                <div className='close-btn' onClick={closeModal}>
                    <i className="material-icons">close</i>
                </div>
                 <h5>{nuevoFlag? 'Nueva ': 'Editar '} solicitud</h5>
                 <div id='general-form'>
                    <div className='row'  style={{width:'100%'}}>
                        <div className="col s4">
                            <div className="input-text">
                                <label style={{color:'blue'}}>Tipo de Permiso</label>
                                <select id={"permiso-select"} value={newVacation.tipo_permiso} onChange={(e)=>setNewVacation({...newVacation,tipo_permiso:e.target.value})}>
                                    <option value="na">Seleccione uno</option>
                                    <option value="Incapacidad"> Incapacidad </option>
                                    <option value="Vacaciones"> Vacaciones </option>
                                    <option value="Permiso"> Permiso </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'  style={{width:'100%'}}>
                        <div className="col s12"> <hr style={{borderTop: 'solid 4px blue'}}></hr> </div>

                        <div className="col s7">
                            <div className="row">
                                <label className='p-5' htmlFor="name">Solicita:</label>
                                <div className='p-5' onChange={onChangeValue}>
                                    <label className="col s4 p-0">
                                        <input class="with-gap" name="group1" type="radio" value="0.5"/>
                                        <span>Medio día</span>
                                    </label>
                                    <label className="col s4 p-0">
                                        <input class="with-gap" name="group1" type="radio" value="1"/>
                                        <span>Solo un día</span>
                                    </label>
                                    <label className="col s4 p-0">
                                        <input class="with-gap" name="group1" type="radio"  value="+1"/>
                                        <span>Mas de 1 día</span>
                                    </label>
                                </div>
                                {showDiasInput&&
                                <div className="col s12 p-10">
                                    <div className="input-text">
                                        <label htmlFor="dias">Numero de días:</label>
                                        <input disabled id="dias" type="number" min={2} className="validate" value={days.length}/>
                                    </div>
                                </div>
                                }
                                <div className="col s12 m12 p-10">
                                    <p>
                                        <label>
                                            <input type="checkbox" value={newVacation.sin_gose_sueldo} onChange={(e)=>setNewVacation({...newVacation,sin_gose_sueldo:e.target.checked})}/>
                                            <span>Sin Goce de Sueldo</span>
                                        </label>
                                    </p>
                                </div>
                                <div className="col s12 m12 p-10">
                                    <div className="input-text">
                                        <label htmlFor="observa2">Comentarios y Notas </label>
                                        <textarea id="observa2" className="materialize-textarea validate" onChange={(e)=>setNewVacation({...newVacation,notas:e.target.value})}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>  
                        </div>

                        <div className="col s5">
                            <label htmlFor="name">Seleccione el o los Día(s):</label>
                            <div className='centrar'>
                                {singleCalendario?
                                <DayPicker 
                                    mode="single"
                                    locale={es}
                                    selected={selectedDay}
                                    onSelect={setSelectedDay}
                                    className="sombra-azul-chica p-10 border-r-6"
                                />
                                :
                                <DayPicker 
                                    mode="multiple"
                                    min={1}
                                    locale={es}
                                    selected={days}
                                    onSelect={setDays}
                                    className="sombra-azul-chica p-10 border-r-6"
                                    footer={footer()}
                                />
                                }
                            </div>
                          
                        </div>
                        {/*
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">Aprobado por:</label>
                                <input id="name" type="text" className="validate"/>
                            </div>
                        </div>*/}
                       
                    </div>
                 </div>
                
            </div>
            <div className="modal-footer">
                <button className="waves-effect waves-green btn-flat" onClick={(e)=>_saveNewVacation()}>Guardar</button>
            </div>
        </div>
    );
};

export default ModalVacation;