import React,{useState} from 'react';
import { Document,Page, View ,Text, Image, StyleSheet} from '@react-pdf/renderer'; 

// https://dev.to/przpiw/react-pdf-rendering-4g7b

const styles = StyleSheet.create({
    page: {
        // display: 'flex',
        // flexDirection: 'row',
        margin: 0,
        padding:0,
    }, 
    section_partes1: {
        margin: 1,
        padding: 5,
        width: '100%',
        borderTop: 0, borderBottom: 2, borderLeft: 0, borderRight: 0, borderColor: '#000000', BorderStyle: 'solid',
      },
    section_partes: {
        margin: 1,
        padding: 5,
        width: '100%',
      },
    section_todos: {
        margin: 0,
        padding:0,
        height:'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    seccion8: {
        width: '80px',
        height:'50px',
        margin: 1,
        padding:1,
        paddingLeft:'10px',
        paddingRight:'10px',
    },
    seccion20: {
        width: '20%',
        height:'50px',
        margin: 1,
        padding:1,
        textAlign: 'rigth',
    },
    seccion25: {
        width: '25%',
        margin: 1,
        padding:1,
    },
    seccion33: {
        width: '33%',
        margin: 1,
        padding:1,
    },
    seccion50: {
        width: '50%',
        height:'50px',
        margin: 1,
        padding:1,
    },
    colum:{
        height:'auto',
        margin: 0,
        padding:1,
    },
    subtext: {
        color: '#000000',
        fontSize:10,
        fontWeight:100,
        margin: 0,
        padding:0,
        lineHeight:1,
        height:'11px',
    },
    label: {
        flex: 1,
        color: '#9e9e9e',
        fontSize:9,
        fontWeight:600,
        margin: 0,
        padding:0,
        paddingTop:'5px',
        marginBottom:'8px',
        lineHeight:1,
        height:'20px',
    },
  });

const DoctoresReport = ({doctores}) => {
    const today = new Date().toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' });
    const [profile, setProfile] = useState({
        type: 'Profile',
        name: 'DOCTORES',
        fecha: '11/02/2023',
        profileImageURL: '/images/logo.png',
        display: true,
        about: 'About...',
        id: 1,
        fechaAltaEmpleado:'19/09/2006'
    })
    
    //   const handleChange = (name, value) => {
    //     setProfile({ ...profile, [name]: value })
    //   }
    //   const LeftSection = ({ profile }) => {
    //     return (
    //       <View style={styles.section_left}>
    //         <Profile profile={profile} />
    //       </View>
    //     )
    // }
    const HeaderLogo = ({ about }) => {
        return (
            <View style={styles.seccion8}> <Image style={styles.profile_img} src={profile.profileImageURL} /></View>
            
        )
      }  
      const Header = ({ about }) => {
        return (
            <View style={styles.seccion20}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Reporte </Text>
                    <Text style={styles.subtext}>{profile.name}</Text>
                </View>
            </View>
        
        )
      }  
      const HeaderDate = ({ about }) => {
        return (
            <View style={styles.seccion20}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Fecha </Text>
                    <Text style={styles.subtext}>{profile.fecha}</Text>
                </View>
            </View>
        
        )
      }  
    
    const SeccionPersonal = ({ about }) => {
        return (
            <View style={styles.seccion25}>
                <View style={styles.colum}>
                    <Text style={styles.label}> ID </Text>
                    <Text style={styles.subtext}>345</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Fecha </Text>
                    <Text style={styles.subtext}>19/08/2006</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Nombre </Text>
                    <Text style={styles.subtext}>Maria del Carmen Romero Reyes</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Email </Text>
                    <Text style={styles.subtext}>carmeromeroreyes@hotmail.com</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Especialidades </Text>
                    <View style={styles.ontain100}>
                        <Text style={styles.subtext}>Cirujano Dentista.</Text>
                        <Text style={styles.subtext}>Rehabilitacion Dental </Text>
                        <Text style={styles.subtext}>Cirujano Dentista.</Text>
                        <Text style={styles.subtext}>Rehabilitacion Dental</Text>   
                    </View>
                </View>
            </View>
        )
    }
    const SeccionSocial = ({ about }) => {
        return (
          <View style={styles.seccion25}>
                <View style={styles.colum}>
                    <Text style={styles.label}>   </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}>   </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Compañía: </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Pagina Web:</Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Instituciones</Text>
                    <View style={styles.ontain100}>
                        <Text style={styles.subtext}> </Text>   
                    </View>
                </View>
          </View>
        )
    }
    const SeccionContact = ({ about }) => {
        return (
          <View style={styles.seccion25}>
                <View style={styles.colum}>
                    <Text style={styles.label}>   </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}>   </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Celular</Text>
                    <Text style={styles.subtext}>3331409567</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Visitado</Text>
                    <Text style={styles.subtext}>No</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
          </View>
        )
    }
    const SeccionLegal = ({ about }) => {
        return (
            <View style={styles.seccion25}>
                <View style={styles.colum}>
                    <Text style={styles.label}>   </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}>   </Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Telefono Particular</Text>
                    <Text style={styles.subtext}>35867126</Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> RFC</Text>
                    <Text style={styles.subtext}> </Text>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Consultorios</Text>
                    <View style={styles.ontain100}>
                        <Text style={styles.subtext}> Clinica Dental Juarez </Text>
                        <Text style={styles.subtext}> </Text>   
                    </View>
                </View>
                <View style={styles.colum}>
                    <Text style={styles.label}> Consultorios</Text>
                    <Text style={styles.subtext}>Clinica Dental Juarez</Text>
                </View>
          </View>
        )
    }

    const SeccionObservaciones = ({ about }) => {
        return (
            <View style={styles.seccion33}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Observaciones </Text>
                    <Text style={styles.subtext}>aaa</Text>
                </View>
            </View>
        )
    }
    const SeccionObservacionesGral = ({ about }) => {
        return (
            <View style={styles.seccion33}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Observaciones Generales</Text>
                    <Text style={styles.subtext}>aaa</Text>
                </View>
            </View>
        )
    }
    const SeccionObservacionesImp = ({ about }) => {
        return (
            <View style={styles.seccion33}>
                <View style={styles.colum}>
                    <Text style={styles.label}> Observaciones Generales</Text>
                    <Text style={styles.subtext}>aaa</Text>
                </View>
            </View>
        )
    }

    

    return (  
    <Document>
        {/* Start of the document*/}
        <Page size={'A4'} orientation={'landscape'} style={styles.page}>
            <View style={styles.section_partes}>
                <View style={styles.section_todos}>
                    <Header about={profile.about}></Header>
                    <View style={styles.seccion50}></View>
                    <HeaderLogo about={profile.about}></HeaderLogo>
                    <HeaderDate about={profile.about}></HeaderDate>
                </View>

                <>
                <View style={styles.section_partes1}>
                    <View style={styles.section_todos}>
                        <SeccionPersonal about={profile.about}></SeccionPersonal>
                        <SeccionSocial about={profile.about}></SeccionSocial>
                        <SeccionLegal about={profile.about}></SeccionLegal>
                        <SeccionContact about={profile.about}></SeccionContact>
                    </View>

                    <View style={styles.section_todos}>
                        <SeccionObservaciones about={profile.seccion33}></SeccionObservaciones>
                        <SeccionObservacionesGral about={profile.seccion33}></SeccionObservacionesGral>
                        <SeccionObservacionesImp about={profile.seccion33}></SeccionObservacionesImp>
                    </View>
                </View>
                </>
                
            </View>
        </Page>
    </Document>
    );
};

export default DoctoresReport;