import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = (props) => {
    const {currentPage,skip,totalItems,itemsPerPage,setItemsPerPage,paginate} = props;
    const pageNumbers = []
   
    for (let index = 1; index <= Math.ceil(totalItems / itemsPerPage) ; index++) {
       pageNumbers.push(index)
    }

    const _changeItemsPerPage = (e) =>{
        paginate(1)
        setItemsPerPage(e.target.value)
    }

    return (
        <div className='row paginate-container'>
            <div className='col s12 m4 centrar' id="pagination-select">
                <div className="col s3">Mostrar</div>
                <div className='col s6'>
                    <select className='browser-default' defaultValue={itemsPerPage} onChange={(e)=>_changeItemsPerPage(e)} >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value={totalItems}>Todos</option>
                    </select>
                </div>
                <div className='col s3'>Por pagina</div>
            </div> 
            <div className='col s12 m8'>
                <ul className="pagination right">
                    <li className={currentPage===1?'disabled':'waves-effect'}>
                        <Link to='#!' onClick={()=>paginate(currentPage-1===0?1:currentPage-1)}>
                        <i className="material-icons">chevron_left</i>
                        </Link>
                    </li>
                    {/* PAGINADOR */}
                    {pageNumbers.map(number=>
                        <li className={number===currentPage?'active':"waves-effect"} key={number}>
                            <Link onClick={()=>paginate(number)} to="#!">{number}</Link>
                        </li>    
                    )}
                    <li className={currentPage===Math.ceil(totalItems / itemsPerPage)?'disabled':'waves-effect'}>
                        <Link onClick={()=>paginate(currentPage+1>Math.ceil(totalItems / itemsPerPage)?currentPage:currentPage+1)} to='#!'>
                            <i className="material-icons">chevron_right</i>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;