import React, {useState} from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import M from 'materialize-css';
import { useNavigate } from 'react-router-dom';

const errorMessages = {
    nombre_producto:"nombre del producto",
    precio_unidad:"precio",
    duracion_aproximada:"duracion",
    activo:"activo",
    dias_preparacion:"días preparacion",
    dias_entrega:"días entrega"
}

const New = () => {
    
    const [servicio,setServicio] = useState({nombre_producto:"", precio_unidad:"", duracion_aproximada:"", activo:1,dias_preparacion:"",dias_entrega:""});
    const navigate = useNavigate();

    const _saveServicio = (e) =>{
        e.preventDefault();
        console.log("guardando...",servicio)
       
       axios.post('api/products',servicio)
        .then(res=>{
            console.log("nuevo servicio..",res.data)
            M.toast({html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>servicio creada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/servicios")
            }, 5000);
        }).catch(e=>{
            console.log("error",e)
            if(e.message){
                let errores = e.response.data.errors;
                for (var key in errores) {
                    let mensaje =`El campo ${errorMessages[key]} es obligatorio. Agregalo para guardar el servico`;
                    M.toast({html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+mensaje+'</span>',classes:"error-notify"})
                }
            } 
        })   
    }
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5 className='p-5'><i className="material-icons">flare</i> Nuevo Servicio</h5>
                    <form onSubmit={_saveServicio} className="row ancho100" id='general-form'>
                        
                        <div className="col s12 m12">
                            <div className="input-text">
                                <label htmlFor="name">Nombre del Servicio *</label>
                                <input id="name" type="text" className="validate" 
                                onChange={(e)=>setServicio({...servicio,nombre_producto:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="domicilio">Precio unidad *</label>
                                <input id="precio" type="number" className="validate" step="0.01"
                                onChange={(e)=>setServicio({...servicio,precio_unidad:e.target.value})} />
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="horario">Duracion Aproximada en Minutos *</label>
                                <input id="horario" type="number" className="validate" step="any" maxlength="10"
                                onChange={(e)=>setServicio({...servicio,duracion_aproximada:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="horario">Duracion de entrega en dias</label>
                                <input id="horario" type="text" className="validate" 
                                onChange={(e)=>setServicio({...servicio,dias_entrega:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="preparacion">Días de preparacion</label>
                                <input id="preparacion" type="number" 
                                step="any" maxlength="10"
                                className="validate" value={servicio.dias_preparacion} 
                                onChange={(e)=>setServicio({...servicio,dias_preparacion:e.target.value})}/>
                            </div>
                        </div>
                        <div
                         className="col s6 m4" style={{minHeight:90}}>   
                            <div className="input-text p-10">
                                <label htmlFor="preparacion">Estado del servicio</label>   
                                <div className="switch input-text p-10">
                                    <label>
                                    Activo
                                    <input id="boletin" type="checkbox" checked={servicio.activo} onChange={(e)=>setServicio({...servicio,activo:e.target.checked})}/>
                                    <span className="lever"></span>
                                    </label>
                                </div>
                            </div>
                        </div>                        
                        <div className='col s12 p-t-35 centrar-r'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default New;