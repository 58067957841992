import axios from 'axios';
import React, { useEffect,useState } from 'react';
import M from 'materialize-css';

const causasVisita = ['Normal','Solicito','Queja','Papeleria','Otro'];

const UpdateVisitaModal = (props) => {
    const [visita,setVisita] = useState({doctor_id:props.doctorId,fecha_visita:'',hora:'',causa_visita:'',observaciones:'',representante:''});
    const [empleados,setEmpleados] = useState([]);

    useEffect(()=>{
        if(props.visitaId > 0){
            axios.get(`api/visits/${props.visitaId}`).then((resp)=>{
                console.log("visita detalle..",resp.data)
                setVisita(resp.data);
              
            }).catch(e=>console.error(e))
        }
    },[props.visitaId])

    useEffect(()=>{
        axios.get('api/employees').then((resp)=>{
            setEmpleados(resp.data);
        }).catch(e=>console.log("Error:",e))
    },[])

    const _update = () =>{
        console.log("update",visita)
    }

    return (
        <div id="updatevisitamodal" className="modal scale-transition scale-in">
            <div className="modal-content">
                <div className='close-btn modal-close '>
                    <i className="material-icons">close</i>
                </div>
                
                <div className="row cont-contain "  id='general-form' style={{background:'#e8f0fe', borderRadius:'12px'}}>
                    <div className='col s12'>
                        <h5 className='p-5'>Visitar a doct@r: {visita.doctor&&visita.doctor.nombre+" "+visita.doctor.apellidos}</h5>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-text">
                            <label htmlFor="name">Fecha</label>
                            <input id="name" type="date" style={{background:'#ffffff'}} 
                            value={visita.fecha_visita}
                            onChange={(e)=>setVisita({...visita,fecha_visita:e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-text">
                            <label htmlFor="hora">Hora</label>
                            <input id="hora" type="time"   style={{background:'#ffffff'}} 
                            value={visita.hora}
                            onChange={(e)=>setVisita({...visita,hora:e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-text">
                            <label htmlFor="name">Representante</label>
                             <select className='browser-default'  value={visita.representante} onChange={(e)=>setVisita({...visita,representante:e.target.value})}  style={{background:'#FFF!important'}} >
                                <option value="">Representante</option>
                                {empleados.map((empleado,i)=>
                                    <option value={empleado.nombre + " " + empleado.apellidos} key={i}>{empleado.nombre} {empleado.apellidos}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-text">
                            <label>Causa de visita</label>
                            <select className='browser-default'  value={visita.causa_visita} onChange={(e)=>setVisita({...visita,causa_visita:e.target.value})}  style={{background:'#FFF!important'}} >
                                <option value="">Causa de la visita</option>
                                {causasVisita.map((causa,i)=>
                                    <option value={causa} key={i}>{causa}</option>
                                )}
                            </select>
                        
                        </div>
                    </div>
                    
                    <div className="col s12 m12">
                        <div className="input-text">
                            <label htmlFor="observa4">Observaciones de la visita</label>
                            <textarea id="observa4" 
                                style={{background:'#ffffff',paddingLeft:10}} 
                            className="materialize-textarea validate"
                            value={visita.observaciones}
                            onChange={(e)=>setVisita({...visita,observaciones:e.target.value})}></textarea>
                        </div>
                    </div>
                </div>
                <div className="col s12 m12 modal-footer m-0 p-12">
                    <div className="right m-10">
                        <button className="btn-blanco waves-effect waves-light" style={{padding:'4px 8px!important',margin:10}} type="submit" name="action">
                            <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Cancelar</div>
                        </button>

                        {props.permisos.visits_update &&
                            <button className="btn-blanco waves-effect waves-light" style={{padding:'4px 8px!important',margin:10}}  onClick={(e)=>_update()}>
                                <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Actualizar</div>
                            </button>
                        }
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateVisitaModal;