import React, { useContext } from 'react';
import { UserContext } from '../../UserContext';
import '../TopMenu/topMenu.scss';
import M from 'materialize-css';

const TopMenu = () => {
    const {user} = useContext(UserContext)
    return (
        <div>
            <div className='container-menu-top'>

                <div className='row ' 
                style={{borderBottom: 'solid 1px rgba(255, 255, 255, .3)', marginBottom:'0!important',}}>  
                    <div className='col m6'></div>
                    <div className='col m6 row m-p-0'> 
                        <div className='row cont-row'>
                            <div className='col s2 m3'></div>
                            
                            <div className='col s2 m1 p-l-5' style={{textAlign:'right'}}> 
                                <div className='sombra-azul-chica' style={{border:'solid 2px #ffffff', borderRadius:'100%', height:'41px', width:'42px', padding:0, margin:0, marginTop:'-6px'}}>
                                    <img className='dentometric' src='/images/logo.png' alt='dento metric' 
                                    style={{height:'100%', borderRadius:'100%', width:'100%'}} />
                                </div>
                            </div>
                            <div className='col s7 m6 name-user' style={{textAlign:'left',paddingTop:'10px',}}>{user.name}</div>

                            <div className='col s1 m2 row m-0' 
                            style={{margin:0, marginTop:0, padding:'0', textAlign:'center', color:'#ffffff',}}> 
                                <div className='row m-0 notificaciones'>
                                    <div className='notificacion-icon'> 
                                        <i className="material-icons">add_alert</i> 
                                    </div>
                                    <div className='notificacion-number'> 
                                        <div className='sombra-azul-chica centrar m-p-0'>
                                            <span>10</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopMenu;