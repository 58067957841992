import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from 'axios';
import { es } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import M from 'materialize-css'
import AddDiasModal from './AddDiasModal';
import EditCitaModal from './EditCitaModal';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const today = new Date();
const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

const Agenda = () => {
    const {user} = useContext(UserContext);
    const [data,setData] = useState({start:today,end:null,sucursal_id:null});
    const [showCalendar,setShowCalendar] = useState('none');
    const [citas,setCitas] = useState([]);
    const [sucursales,setSucursales] = useState([]);
    const [selectedDay,setSelectedDay] = useState(today);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState();
    const [citaSeleccionada,setCitaSeleccionada] = useState(null);
    const [spiner,setSpiner] = useState(false);
    const [sortBy, setSortBy] = useState({
        field:"",
        sortBy:"ASC"
    });
    const [permisos,setPermisos] = useState({
        appointments_list_read:null,
        appointments_update:null,
        appointments_clean:null,
        appointments_btn_add_days:null
    })
    const [loaded,setLoaded] = useState(false)

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                appointments_list_read:checkPermiso("appointments_list_read",user.permissions),
                appointments_update:checkPermiso("appointments_update",user.permissions),
                appointments_clean:checkPermiso("appointments_clean",user.permissions),
                appointments_btn_add_days:checkPermiso("appointments_btn_add_days",user.permissions)
            }));
       }
    },[user]);

    useEffect(()=>{
        (permisos.appointments_list_read !== null) && setLoaded(true);
    },[permisos])

    useEffect(()=>{
        axios.get('/api/sucursals').then((resp)=>{
            setSucursales(resp.data);
            M.FormSelect.init(document.querySelector('#sucursal'));
        }).catch(e=>console.log("Error: ",e)); 
    },[]);

    useEffect(()=>{
        if(selectedDay){
            let formatedDay = selectedDay.toISOString().split('T')[0]; //format(selectedDay, 'Y-LL-dd');
            console.log("selectedday",formatedDay);
            console.log(formatedDay);
            setData({...data,start:formatedDay,sucursal_id:sucursalSeleccionada});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDay,sucursalSeleccionada]);

    useEffect(()=>{
       getCitas();
    },[data])

    const getCitas = () =>{
        axios.post('/api/get_appointments',data).then((resp)=>{
            console.log("Citas",resp.data);
            setCitas(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const _handleSort = (field) =>{
        if(field !== sortBy.field){
            setSortBy({...sortBy,field:field,sortBy:"DESC"});
        }else{
            setSortBy({...sortBy,sortBy:sortBy.sortBy === "DESC"?"ASC":"DESC"})
        }
    }

    return (
        <div>
            <div className='container-admin-menu '><Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                    {data.sucursal_id > 0 &&  <AddDiasModal sucursal_id={data.sucursal_id}></AddDiasModal>}
                    <EditCitaModal citaId={citaSeleccionada} citas={citas} setCitas={setCitas} setCitaSeleccionada={setCitaSeleccionada} appointments_clean={permisos.appointments_clean}></EditCitaModal>
                    <div className='row col s12 info-badge border-r-10 m-p-0'>
                        <div className='col s12 m9 m-p-0'>
                            <div className='row m-p-0 col s12'>
                                <div className="col s12 m12 m-p-0">
                                    <h6><b>Fecha:</b> {selectedDay.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' })}</h6>
                                </div>
                                <div className="input-field centrar col s12 m6 m-p-0">
                                    <div className='btn-blanco m-p-0' id='selectiones' style={{width:'100%'}}>
                                        <select className='browser-default border-r-4 m-0 p-0-3' id='sucursal' defaultValue={sucursalSeleccionada} onChange={(e)=>setSucursalSeleccionada(e.target.value)}
                                        style={{background:'#fff', margin:'0!important', padding:'5px 2px!important', height:'32px', fontSize:'14px'}}>
                                            <option value="0" className='m-0' disabled selected>Seleccione la sucursal</option>
                                            {sucursales.map( (sucursal)=>
                                            <option value={sucursal.id} key={sucursal.id} className='m-0'>{sucursal.nombre_sucursal}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>  
                                <div className='col s12 m3 centrar m-p-0'>
                                    <button className="btn-blanco row" onClick={()=>setSelectedDay(today)} style={{width:'70%'}}>
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/calendar.png' alt='doctor dento metric' style={{width:'20px',}} />
                                        </div>    
                                        <div className='col s10 m10 m-p-0' style={{fontSize: '12px', cursor:'pointer'}}>&nbsp;Citas de hoy</div>
                                    </button>
                                </div>
                                <div className='col s12 m3 centrar-l m-p-0'>
                                {permisos.appointments_btn_add_days && data.sucursal_id > 0 &&
                                    <button className="btn-blanco row modal-trigger" data-target="modal-add-dias"  style={{width:'70%'}}>
                                        <div className='col s2 m2 m-p-0 centrar'>
                                            <img className='col s2 m2 m-p-0 dentometric centrar' src='/images/calendar.png' alt='doctor dento metric' style={{width:'20px',}} />
                                        </div>    
                                        <div className='col s10 m10 m-p-0' style={{fontSize: '12px', cursor:'pointer'}}>&nbsp;Agregar dias</div>
                                    </button>
                                }
                                </div>
                            </div>
                        </div>
                        <div className='col s12 m3 m-p-0'>
                            {showCalendar !=='none' ?
                                <div className='' style={{display:showCalendar}} onMouseLeave={()=>setShowCalendar('none') } >
                                    <DayPicker 
                                        mode="single"
                                        locale={es}
                                        selected={selectedDay}
                                        onSelect={setSelectedDay}
                                        className="p-10 border-r-6"
                                        />
                                </div> :
                                <div className='row m-p-0'>
                                    <img className='col s12 m6 dentometric puntero' src='/images/calendar2.png' style={{width:86}}
                                        alt='doctor dento metric' onClick={()=>setShowCalendar('block')}/>
                                    <div className='col s12 m6 puntero' onClick={()=>setShowCalendar('block')}><br></br><br></br>Ver Calendario</div>
                                </div>
                            }
                        </div>
                    </div>
                    {loaded ?
                        permisos.appointments_list_read ?
                             <div className='m-p-0'>
                                <div>
                                    <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                                        <div className='table-header row m-t-20 '>
                                            <div className='col s12 m1 centrar'> Fecha <i className="material-icons" onClick={(e)=>{_handleSort("fecha_visita")}}>unfold_more</i></div>
                                            <div className='col s12 m1 centrar'> Hora <i className="material-icons" onClick={(e)=>{_handleSort("hora")}}>unfold_more</i></div>
                                            <div className='col s12 m2 centrar'> Paciente &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("paciente")}}>unfold_more</i></div>
                                            <div className='col s12 m1 centrar'> Telefono </div>
                                            <div className='col s10 m2 centrar'> Estudios</div>
                                            <div className='col s10 m2 centrar'> Doctor &nbsp; <i className="material-icons" onClick={(e)=>{_handleSort("doctor")}}>unfold_more</i></div>
                                            <div className='col s12 m2 centrar'> Observaciones</div>
                                            <div className='col s12 m1'> Acciones</div>
                                        </div>
                                        
                                        <div className='table-container'>
                                        {citas.map((cita)=>
                                            <div className='row' key={cita.id}>
                                                <div className='col s2 m1 centrar '>
                                                    <div className='centrado'>{cita.fecha}</div>
                                                </div>
                                                <div className='col s3 m1 centrar'>
                                                    <div className='centrado'>{cita.hora}</div>
                                                </div>
                                                <div className='col s2 m2 centrar'>
                                                    <div>{cita.paciente} </div>
                                                </div>
                                                <div className='col s2 m1 centrar'>
                                                    <div className='centrado'>{cita.telefono}</div>
                                                </div>
                                                <div className='col s3 m2'>
                                                    <div className=''>
                                                    {cita.products.map(p=>
                                                        <p className='p-m-0' key={p.id}>{p.nombre_producto}</p>
                                                    )}
                                                    </div>
                                                </div>
                                                <div className='col s3 m2 centrar'>
                                                    <div className='centrado'>
                                                        {cita.doctor} 
                                                    </div>
                                                </div>
                                                <div className='col s3 m2 centrar'>
                                                    <div className='centrado'>{cita.observaciones}</div>
                                                </div>
                                                <div className='col s12 m1 movil-oculto'>
                                                    <div className='row centrado cont-textos'>
                                                        {permisos.appointments_update &&
                                                        <div className='col m6 m-p-0 centrar movil-100 modal-trigger'  data-target="modal-edit-cita" onClick={(e)=>setCitaSeleccionada(cita.id)}>
                                                            <i className="material-icons m-p-0" style={{fontSize:'20px',color:'green',cursor:'pointer'}} >create</i>
                                                        </div>
                                                        }
                                                        <div className='col m6 m-p-0 centrar movil-100'>
                                                            <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} >delete_forever</i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        </div>    
                                        
                                    </div>
                                </div>
                            </div> 
                            :
                            <NoPermissions />
                        :
                        <Loader />
                    }

                </div>
            </div>
        </div>
    );
};

export default Agenda;