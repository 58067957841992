import React, { useContext, useEffect,useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import PedidosDoctores from './PedidosDoctores';
import DetallePedido from './DetallePedido';
import PedidosPaciente from './PedidosPaciente';
import PedidosCaja from './PedidosCaja';
import axios from '../../axios';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import Loader from '../../Layout/Loaders/Loader';

const New = () => {
    const today = new Date();
    const {user} = useContext(UserContext);
    //PENDIENTE JALAR LA SUCURSAL DEL USUARIO
    const [order,setOrder] = useState({sucursal_id:1,direccion_envio:"",con_cita:0,fecha_pedido:today,employee_entregado_rx_por_id:null,employee_atendido_por_id:null});
    const [orderDetails,setOrderDetails] = useState([]);
    const [orderPayment,setOrderPayment] = useState({
        order_id:null,
        iva_porciento:0,
        metodo_de_pago:"",
        precio_envio:"",
        anticipo1:"0",
        anticipo2:"0",
        anticipo3:"0",
        subtotal:0,
        total:0,
        saldo:0,
        saldo2:0,
        observaciones:"",
        factura:0
    });

    const [empleados,setEmpleados] = useState();
    const [sucursales,setSucursales] = useState();
    const [productos,setProductos] = useState([{id:null,nombre_producto:"",precio_unidad:0,cantidad:0,precio_descuento:0,dias_entrega:0}]);
    const [total,setTotal] = useState(0);
    var elem = document.querySelectorAll('#new-pedido-accordion');
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        orders_create:null,
        orders_update_doctor:null,
        orders_update_client:null,
        orders_update_products:null,
        orders_update_cash_register:null,
        orders_update_atendio_recepcion_select:null,
        orders_update_atendio_caja_select:null,
        orders_update_sucursal_select:null,
        patient_create:null,
        patient_update:null
    });

    M.Collapsible.init(elem, {
        onCloseEnd:function(tab){
            changeTab(tab)
        }
    });
  
    useEffect(()=>{
        getSucursales();
        getEmpleados();
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                orders_create:checkPermiso("orders_create",user.permissions),
                orders_update_doctor:checkPermiso("orders_update_doctor",user.permissions),
                orders_update_client:checkPermiso("orders_update_client",user.permissions),
                orders_update_products:checkPermiso("orders_update_products",user.permissions),
                orders_update_cash_register:checkPermiso("orders_update_cash_register",user.permissions),
                orders_update_atendio_recepcion_select:checkPermiso("orders_update_atendio_recepcion_select",user.permissions),
                orders_update_atendio_caja_select:checkPermiso("orders_update_atendio_caja_select",user.permissions),
                orders_update_sucursal_select:checkPermiso("orders_update_sucursal_select",user.permissions),
                patient_create:checkPermiso("patient_create",user.permissions),
                patient_update:checkPermiso("patient_update",user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        (permisos.orders_create !== null) && setLoaded(true);
    },[permisos])

    const changeTab = (e) =>{
        let tabID = e.id;
        switch(tabID){
            case "doctores-tab":
                console.log("Doctores completo a guardar",order)
                updateOrder();
            break;

            case "pacientes-tab":
                console.log("Paciente completo a guardar",order)
                updateOrder();
            break;

            case "pedidos-tab":
                console.log("Pedidos completo a guardar",order)
                updateOrder();
                buildOrderDetails();
                updateOrderPayment();
            break;

            default: break;
        }
    }
    // *************UPDATE ORDER FUNCTIONS START *****************
    const updateOrder=()=>{
        let message = "Pedido actualizado correctamente"
        axios.put(`api/orders/${order.id}`,order).then(resp=>{
            console.log("update...",resp);
            setOrder(resp.data);
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+message+'</span>',classes:"success-notify"})
        }).catch(e=>console.log("Error: ",e))
    } 

    const buildOrderDetails=()=>{
        if(productos.length > 0){
            let detallesTemporal = productos.map(producto=>{
                let index = orderDetails.findIndex((item)=> item.product_id == producto.id);
                if(index !== -1){
                    return {
                        id:orderDetails[index].id,
                        order_id:producto.order_id,
                        product_id:producto.id,
                        precio_unidad:producto.precio_unidad,
                        cantidad:producto.cantidad,
                        descuento:producto.descuento
                    }
                }else{
                    return {
                        order_id:producto.order_id,
                        product_id:producto.id,
                        precio_unidad:producto.precio_unidad,
                        cantidad:producto.cantidad,
                        descuento:producto.descuento
                    }
                }
            })
            updateOrderDetails(detallesTemporal);
        }  
    }

    const updateOrderDetails = (detallesTemporal) =>{
        if(detallesTemporal.length > 0){
            axios.post('api/save-order-details',detallesTemporal).then(resp=>{
                console.log("actualizado",resp.data)
                setOrderDetails(resp.data);
            }).catch(e=>console.log("Error",e));
        } 
    } 
    const updateOrderPayment = () =>{
        axios.put(`api/order-payment/${orderPayment.id}`,orderPayment).then(resp=>{
            console.log("actualizado",resp.data)
            setOrderPayment(resp.data);
        }).catch(e=>console.log("Error",e));
        
    } 

    // *************UPDATE ORDER FUNCTIONS END *****************
    const getSucursales = () =>{
        axios.get('api/sucursals').then((resp)=>{
            setSucursales(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }
    //NUEVA ORDEN
    const _creatNewOrder = () =>{
        console.log("nuevo Pedido");
        axios.post("api/orders",order).then((resp)=>{
            setOrder(resp.data);
           _createOrderPayment(resp.data.id);
        }).catch(e=>console.log("Error: ",e));
    }
    //NUEVO ORDER PAYMENT
    const _createOrderPayment = (orderId) =>{
        console.log("nuevo order id",orderId)
        axios.post("api/order-payment",{order_id:orderId}).then((resp)=>{
            setOrderPayment(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }

    const _handleSucursal = (sucID) =>{
        setOrder({...order,sucursal_id:sucID});
    } 

    const getEmpleados = () =>{
        axios.get('api/employees').then((resp)=>{
            setEmpleados(resp.data);
        }).catch(e=>console.log("Error: ",e));
    }
 
    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1'>
                    <div className='container-admin-container m-p-0'>
                        <div className='row col s12 info-badge border-r-10 m-p-0' style={{bordeRadius:'10px!important', padding:'0px!important'}}>
                            {(order.id) ?
                            <>
                                <div className="col s12 m12 m-p-0 row ancho100"> 
                                    <div className="col s12 m6 m-p-0 row" > Crea un nuevo pedido </div>
                                    <div className="col s12 m3 m-p-0 row" >
                                        <div className="input-text col s6 m6 m-0 p-5 centrar">
                                            <label>ID Pedido:</label>
                                        </div>
                                        <div className="col s6 m6 m-p-0 centrar">
                                            <div className='p-5 ancho100'>{order.id}</div>
                                        </div>
                                    </div>
                                    <div className="col s12 m3 m-p-0 row" >
                                        <div className="input-text col s6 m6 m-p-0 centrar">
                                            <label>Fecha pedido:</label>
                                        </div>
                                        <div className="col s6 m6 m-p-0 left">
                                            {new Date(order?.created_at).toLocaleDateString("es-MX", { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                        </div>
                                    </div>                                    
                                </div>
                                    
                                <div className="col s12 m12 m-p-0 row">
                                    <div className="col s12 m4 m-0 p-2 row">
                                    {permisos.orders_update_atendio_recepcion_select &&
                                        <div className="input-text m-p-0">
                                            <label>Atencion Recepcion:</label>
                                            <select className='browser-default custom-select' style={{ height:'20px!important'}}>
                                                <option value=""> Selecciona una opcion </option>
                                                {empleados.map((empleado,index)=> 
                                                    empleado.employee_type_id === 5 && 
                                                        <option key={index} value={empleado.id} >{empleado.apellidos} {empleado.nombre}</option>
                                                )}
                                            </select>
                                        </div>
                                    }
                                    </div>
                                    <div className="col s12 m4 m-0 p-2 row">
                                    {permisos.orders_update_atendio_caja_select &&
                                        <div className="input-text m-p-0">
                                            <label>Atencion Caja:</label>
                                            <select className='browser-default custom-select' style={{ height:'20px!important'}} value={order.sucursal_id} 
                                                onChange={(e)=>_handleSucursal(e.target.value)}>
                                                <option value=""> Selecciona una opcion </option>
                                                {empleados.map((empleado,index)=> 
                                                    empleado.employee_type_id == 4 &&
                                                        <option key={index} value={empleado.id} >{empleado.apellidos} {empleado.nombre}</option>
                                                )}
                                            </select>
                                        </div>
                                    }
                                    </div>
                                    <div className="col s12 m4 m-0 p-2 row">
                                        {permisos.orders_update_sucursal_select &&
                                        <div className="input-text m-p-0">
                                            <label>Atencion Sucursal:</label>
                                            <select className='browser-default custom-select m-p-0' style={{height:'20px!important'}}>
                                                <option value="" disabled selected> Selecciona una opcion </option>
                                                {sucursales.map((suc,index)=> 
                                                    <option key={index} value={suc.id} >{suc.nombre_sucursal}</option>
                                                )}
                                            </select>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <div className="col s12 m12 row m-0 p-20">
                                <div className='col s12 m3 m-p-0' 
                                style={{height:'44px', width:'44px', padding:0, margin:'0!important',}}>
                                    <img className='dentometric m-p-0' src='/images/completar.png' alt='dento metric' 
                                    style={{height:'100%', width:'100%'}} />
                                </div>
                                <div className="col s12 m6 m-6 centar"> Registrar nuevo pedido </div> 
                                {permisos.orders_create &&
                                <div className="col s12 m4 m-3">
                                    <button className='btn-info width-full' onClick={(e)=>_creatNewOrder()}> Crear Pedido </button>
                                </div>
                                }
                                
                            </div>
                            }
                               
                        </div>
                        <div> &nbsp; </div>
                       {//TABS
                        order.id?
                        <div className='col m12 grey lighten-5 border-r-10 m-p-0'>
                            <ul className="collapsible expandable sombra-azul-chica border-r-10" id="new-pedido-accordion">
                                {permisos.orders_update_doctor &&
                                <li className='border-r-10' id="doctores-tab">
                                    <div className="collapsible-header border-r-10 m-0 p-8">
                                        <img className='docFoto' src='/images/doc.png' alt='doctor dento metric' 
                                        style={{height:'40px', width:'40px'}}/> 
                                        <span className='font16 t-azul' style={{borde:'solid', fontWeight:'bold', paddingLeft:'20px', paddingTop:'10px'}}> Doctor </span>
                                    </div>
                                    <div className="collapsible-body m-p-0"> 
                                        <PedidosDoctores order={order} setOrder={setOrder}/>
                                    </div>
                                </li>
                                }
                                {permisos.orders_update_client &&
                                <li className='border-r-10' id="pacientes-tab">
                                    <div className="collapsible-header border-r-10 m-0 p-8">
                                        <img className='docFoto' src='/images/paciente.png' alt='paciente dento metric' 
                                        style={{height:'40px', width:'40px'}}/> 
                                        <span className='font16 t-azul' style={{borde:'solid', fontWeight:'bold', paddingLeft:'20px', paddingTop:'10px'}}> Paciente</span>
                                    </div>
                                    <div className="collapsible-body m-p-0 "> <PedidosPaciente order={order} setOrder={setOrder} patient_create={permisos.patient_create} patient_update={permisos.patient_update}/> </div>
                                </li>
                                }
                                {permisos.orders_update_products &&
                                <li className='border-r-10' id="pedidos-tab">
                                    <div className="collapsible-header border-r-10 m-0 p-8">
                                        <img className='docFoto' src='/images/pedidos.png' alt='paciente dento metric' 
                                        style={{height:'40px', width:'40px'}}/> 
                                        <span className='font16 t-azul' style={{borde:'solid', fontWeight:'bold', paddingLeft:'20px', paddingTop:'10px'}}> Pedido </span>
                                    </div>
                                    <div className="collapsible-body">
                                        <DetallePedido 
                                            productos={productos} 
                                            setProductos={setProductos} 
                                            total={total} setTotal={setTotal} 
                                            order={order} 
                                            setOrder={setOrder}
                                            orderPayment={orderPayment}
                                            setOrderPayment={setOrderPayment}
                                            />
                                    </div>
                                </li>
                                }
                                {permisos.orders_update_cash_register &&
                                <li className='border-r-10' id="caja-tab">
                                    <div className="collapsible-header border-r-10 m-0 p-10">
                                        <img className='docFoto' src='/images/caja.png' alt='paciente dento metric' 
                                        style={{height:'40px', width:'40px'}}/> 
                                        <span className='font16 t-azul' style={{borde:'solid', fontWeight:'bold', paddingLeft:'20px', paddingTop:'10px'}}> Caja 
                                        / </span>
                                        <img className='docFoto' src='/images/money.png' alt='paciente dento metric' 
                                        style={{height:'40px', width:'40px'}}/> 
                                        <span className='font16 t-azul' style={{borde:'solid', fontWeight:'bold', paddingTop:'10px'}}>Pagos </span>
                                    </div>
                                    <div className="collapsible-body m-p-0"> 
                                        <PedidosCaja 
                                        productos={productos} 
                                        total={total}
                                        order={order}
                                        orderPayment={orderPayment}
                                        setOrderPayment={setOrderPayment}
                                        /> 
                                    </div>
                                </li>
                                }
                            </ul>  
                        </div>
                        :
                        loaded ?
                            <div className='m-t-25'>
                                <div className="col s12 m6 m-6 centrar-r">Para inciar a crear un nuevo pedido pulsa el boton</div>
                                <div className='col s12 m3 m-p-0' 
                                    style={{height:'65px', width:'65px', padding:0, margin:'0!important',}}>
                                    <img className='dentometric m-p-0' src='/images/clic.png' alt='dento metric' 
                                    style={{height:'100%', width:'100%', transform: 'rotate(45deg)'}} />
                                </div>
                                {/** <Spinner size="big"/> **/}
                            </div>
                            :
                            <Loader />
                        
                       }
                    </div>            
                </div>
            </div>
        </>
        
    );
};

export default New;