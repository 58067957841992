import React, { useContext, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import esLocale from '@fullcalendar/core/locales/es';
import axios from 'axios';
import VisitaFormulario from '../Doctores/VisitaFormulario';
import M from 'materialize-css';
import UpdateVisitaModal from './UpdateVisitaModal';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const VisitasDoctores = () => {
    const {user} = useContext(UserContext);
    const [calendarView,setCalendarView] = useState('dayGridMonth');
    const [visitas,setVisitas] = useState([]);
    const [calendarApi,setCalendarApi] = useState();
    const [doctorId,setDoctorId] = useState(null);
    const [newVisita,setNewVisita] = useState(false);
    const [visitaId,setVisitaId] = useState(null);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        visits_create:null,
        visits_read:null,
        visits_update:null
    });
    const calendar = useRef();

    useEffect(()=>{
        M.Modal.init(document.querySelector('#visitamodal'));
        M.Modal.init(document.querySelector('#updatevisitamodal'));
        setCalendarApi(calendar.current.getApi());
       

        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                visits_create:checkPermiso("visits_create",user.permissions),
                visits_read:checkPermiso("visits_read",user.permissions),
                visits_update:checkPermiso("visits_updater",user.permissions)
            }));
             //get Visitas
            getVisitas();
        }
       
    },[user])

    useEffect(()=>{
        (permisos.visits_read !== null) && setLoaded(true);
    },[permisos])

     
    useEffect(()=>{
        axios.get('api/doctors').then((resp)=>{
            var doctoresArray = resp.data;
            var dataDoctores = {};
            for (var i = 0; i < doctoresArray.length; i++) {
              dataDoctores[doctoresArray[i].id +".- " + doctoresArray[i].nombre+" "+doctoresArray[i].apellidos] = null; 
            }
            M.Autocomplete.init(document.querySelectorAll('#autocomplete-doctors'), {
                data: dataDoctores,
                minLength:0, 
                limit:20,
                onAutocomplete:(value)=>handleDoctor(value)
            })
        }).catch(e=>console.error(e))
    },[])

    useEffect(()=>{
        if(newVisita){
            let instance = M.Modal.getInstance(document.querySelector('#visitamodal'));
            instance.close();
            setNewVisita(false);
            getVisitas();
            M.toast({
                html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Visita Agendada Correctamente</span>',
                classes:"success-notify"
            })
        }
    },[newVisita])

    const getVisitas = () =>{
        axios.get('api/visits').then((res)=>{
            console.log('console 111', res.data)
            let eventParse = res.data.map(d=>{
                return {
                    id:d.id,
                    title:"Visitar a: ",
                    start:d.fecha_visita,
                    color:"#73C2FB",
                    extendedProps: {
                        doctor:d.doctor.nombre,
                        hora:d.hora,
                        cel:d.doctor.celular
                      }
                }
            })
            console.log('console 222', eventParse)
            setVisitas(eventParse)
        }).catch(e=>console.log(e))
    }

    
    /** Inicio --- Funciones del calendario **/
    const changeCalendarView = (view) =>{
        console.log('Inicio --- Funciones del calendario')
        setCalendarView(view)
        calendarApi.changeView(view)
        console.log('calendarView', calendarView)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                 <b className='m-p-0'><i style={{color:'#0033ff'}}>{eventInfo.event.title}</i>&nbsp; 
                    {eventInfo.event.extendedProps.excedido > 0 &&<i className="material-icons" style={{fontSize:16,position:'absolute',top:2,right:5}}>alarm</i>}
                 </b>
                 {eventInfo.event.extendedProps.doctor !== "" ? 
                    <>
                        <p className='m-p-0' style={{color:'#0033ff', fontWeight:400, fontSize:'10px',}}>
                            <b className='m-p-0'>
                                <i className="large material-icons m-p-0"
                                style={{fontSize:'10px', color:'#fff'}}>person_outline</i>
                            {eventInfo.event.extendedProps.doctor}</b>
                        </p>
                        <p className='m-p-0' style={{color:'#0033ff', fontWeight:400, fontSize:'10px',}}>
                            <b>
                                <i className="large material-icons"
                                style={{fontSize:'10px', color:'#fff'}}>access_alarms</i>                             
                                {eventInfo.event.extendedProps.hora}</b></p>
                        <p className='m-p-0' style={{color:'#0033ff', fontWeight:400, fontSize:'10px',}}>
                            <b>
                                <i className="large material-icons"
                                style={{fontSize:'10px', color:'#fff'}}>settings_cell</i>
                                {eventInfo.event.extendedProps.cel}</b></p>
                    </>
                    :
                    <p className='m-p-0'>Disponible para agendar</p>
                 }
            </>
        )
    };

    const handleDoctor = (value) =>{
        console.log("doctor...",value)
        let tempArray = value.split(".- ");
        if(tempArray.length > 0){
            let intId =  parseInt(tempArray[0]);
            setDoctorId(intId)
        }else{
            setDoctorId(null)
        }
    }

    const _handleDateClick = (arg) => { // bind with an arrow function
        alert(arg.dateStr);
       
    }

    const _eventDetail = ({event}) =>{
        let instance = M.Modal.getInstance(document.querySelector('#updatevisitamodal'));
        instance.open();
        setVisitaId(event.id)
    }
    const _eventUpdate = ({event})=>{
        console.log("la fecha",event.startStr)
        let nuevaFecha = event.startStr;
        let id = event.id;

        axios.put(`api/visit_update_date/${id}`,{fecha_visita:nuevaFecha})
        .then(resp=>{
            console.log(resp)
            if(resp.status===200){
                M.toast({
                    html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Visita actualizada correctamente</span>',
                    classes:"success-notify"
                })
            }
        }).catch(e=>{
            console.log(e)
            getVisitas();
        })
    }

    const visitaModalClose = () =>{
        console.log("cerrado..");
        let instanceAuto = M.Autocomplete.getInstance(document.querySelector('#autocomplete-doctors'));
        instanceAuto.el.value = "";
        setDoctorId(null);
        let instance = M.Modal.getInstance(document.querySelector('#visitamodal'));
        instance.close();
    }

    return (
        <div>
            <div id="visitamodal" className="modal">
                <div className="modal-content m-p-0">
                    <div className='close-btn' onClick={visitaModalClose}>
                        <i className="material-icons">close</i>
                    </div>
                    
                    <div className='row m-p-0'>
                        <div className='col s12 p-20'>
                            <div className='input-field m-10'>
                                <label htmlFor="autocomplete-doctors">Doctor</label>
                                <input type="text" id="autocomplete-doctors" autoComplete="off" className="autocomplete" style={{border:'solid 1px #dee2e6',borderRadius:'4px', padding:'0 0 0 6px'}}/>
                            </div>
                        </div>
                        {doctorId > 0 ?
                            <div className='col s12 row'>
                                
                                <VisitaFormulario
                                doctorId={doctorId} doctor_offices={[]} setNewVisita={setNewVisita}/>
                                <button  id='botonInmovil'
                                onClick={(e)=>visitaModalClose()}>
                                    <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Cancelar</div>
                                </button>
                            </div>:
                            <div className='col s11 centrar'> 
                                <div className='col notification-info'>
                                    <p>Selecciona un doctor para agendar una visita!</p>
                                </div>
                            </div> 
                        }
                      
                    </div>
                    {doctorId > 0 ?
                <div style={{display:'none', height:'1px'}}>.</div>
                :
                <div className="modal-footer m-0 p-12">
                    <div className="right m-10">
                        <button className="btn-blanco waves-effect waves-light row" style={{padding:'4px 8px!important'}} onClick={(e)=>visitaModalClose()}>
                            <div className='col s10 m10 m-p-0' style={{fontSize: '14px', cursor:'pointer' }}> &nbsp;Cancelar</div>
                        </button>
                    </div>
                </div>}
                </div>
                
            </div>  
            <UpdateVisitaModal visitaId={visitaId} permisos={permisos}/>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                    <div className="mt-3 mt-md-0 text-md-right col-lg-6 m-p-0 row" style={{textAlign:'right',}}>
                        <div className='col s2 m2 m-p-0 row'>
                            <div className="col s3 m4 m-0 p-5">
                                <img className='dentometric' src='/images/calendar2.png' style={{width:'100%'}}
                                alt='doctor dento metric' />
                            </div>
                            <div className="col s3 m8 m-0 p-5 actual centrar" style={{color:'#000000'}}><br></br>Visitas</div>
                        </div>
                        <div className='col s6 m4 m-p-0 centrar'></div>
                        <div className='col s4 m6 m-p-0 centrar'>
                            <div className=' m-p-0'>
                                {permisos.visits_create &&
                                <button type="button" data-calendar-view="month" 
                                data-target="visitamodal"
                                className="btn-neutral btn btn-default btn-sm btn-azul modal-trigger">Nueva Visita</button>
                                }
                                <button type="button" data-calendar-view="month" 
                                onClick={(e)=>changeCalendarView('dayGridMonth')}
                                className="btn-neutral btn btn-default btn-sm btn-azul"> Ver Mes</button>
                                <button type="button" data-calendar-view="basicWeek" 
                                className="btn-neutral btn btn-default btn-sm btn-azul"
                                onClick={(e)=>changeCalendarView('dayGridWeek')}>Ver Semana</button>
                                <button type="button" data-calendar-view="basicDay" 
                                className="btn-neutral btn btn-default btn-sm btn-azul"
                                onClick={(e)=>changeCalendarView('dayGridDay')}>Ver Día</button>
                            </div>
                        </div>
                    </div>
                    <FullCalendar ref={calendar}
                        plugins={[ dayGridPlugin, interactionPlugin ]}
                        locale={esLocale}
                        initialView={calendarView}
                        selectable={true}
                        selectHelper={true}
                        editable= {true}
                        events={permisos.visits_read ? visitas:[]}
                        eventContent={renderEventContent}
                        dateClick={_handleDateClick}
                        eventClick={(e)=>_eventDetail(e)}
                        eventDrop={(e)=>_eventUpdate(e)}
                    />
                </div>
            </div>
        </div>
    );
};

export default VisitasDoctores;