import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import Pagination from '../../../Layout/Pagination/Pagination';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import M from 'materialize-css';
import { UserContext } from '../../../UserContext';
import NoPermissions from '../../NoPermissions/NoPermissions';

const Paises = () => {
    const user = useContext(UserContext);
    const [paises,setPaises] = useState([])
    //estados para componente de paginacion
    const [itemsPerPage,setItemsPerPage] = useState(25)
    const [skip, setSkip] = useState(0)
    const [currentPage,setCurrentPage] = useState(1)
    const [data,setData] = useState({"description":"","status":1})
    const [permisos,setPermisos] = useState({configs_countries_module:null})
   
    useEffect(()=>{
        //console.log("user",user)
        if(user.user.id){
            setPermisos({...permisos,configs_countries_module:checkPermiso("configs_countries_module",user.user.permissions)})
        }
    },[user])

    useEffect(()=>{
        //console.log("permisos",permisos);
        (permisos.configs_countries_module !== null && permisos.configs_countries_module) && getCountries();
    },[permisos])

    const getCountries = () => {
        M.Modal.init(document.querySelector('#new-country'),{dismissible:false});
        axios.get('api/countries')
        .then((res)=>{
            console.log("paises ",res.data)
            setPaises(res.data)
        }).catch(e=>console.log("error",e))
    }

    const _delete = (id) =>{
        console.log("holis")  
    }

    const _savePais = (e) =>{
        e.preventDefault()
        console.log("nuevo",data)
        axios.post('api/countries',data)
        .then((res)=>{    
            M.toast({
                html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Pais creado exitosamente</span>',
                classes:"success-notify"
            })
            setData({"description":"","status":1})
            setPaises(pais => [...pais,res.data] );
        }).catch(e=>console.log(e))
    }

    const _closemodal = () =>{
        let instance = M.Modal.getInstance(document.querySelector('#new-country'))
        instance.close()
        setData({"description":"","status":1})
    }

    //FUNCION PARA PAGINADOR
    const paginate = (paginateNumber)=>{
        setCurrentPage(paginateNumber)
    }

    //current Items Paginador
    const indexLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexLastItem - itemsPerPage;
    const currentItems = paises.slice(indexOfFirstItem,indexLastItem)
    return (
       
        <>
            <div className='container-admin-menu'> 
                <Breadcrumb hasButton={false} hasModalButton={"new-country"} hasFilters={false}/>
            </div> 
          
                <div className='container-admin-container'>
                    <div className='container-admin z-depth-1'>  
                    {permisos.configs_countries_module ?
                        <>
                            <div className='table'>
                                <div className="table-item-counter">Total Paises: {paises.length}</div>
                                <div className='table-header row movil-oculto'>
                                    <div className='col m1'>ID</div>
                                    <div className='col m5'>País</div>
                                    <div className='col m2'>No. Estados</div>
                                    <div className='col m2'>Estatus</div>
                                    <div className='col m2 centrado'>Acciones</div>
                                </div>
                                {currentItems.map(pais=>
                                <div className='table-container row' key={pais.id}>
                                    <div className='col s8 m1 movil-oculto'>
                                        <div className='cont-textos'>{pais.id}</div>
                                    </div>
                                    <div className='col s12 movil-visible'>
                                        <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                            <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{pais.id}</div> </div>
                                            <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                                <div className='cont-textos row'>
                                                    <Link to={`${pais.id}`} style={{color:'green'}}
                                                    className="col s6 m6 push-m3 m-p-0 centrar">
                                                        <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                                    </Link>
                                                    <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(pais.id)}>delete_forever</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col s4 movil-visible'>
                                        <div className='table-header-movil'>Pais </div>
                                    </div>
                                    <div className='col s8 m5'>
                                        <div className='cont-textos'>{pais.description}</div>
                                    </div>
                                    <div className='col s8 m2'>
                                        <div className='cont-textos'>{pais.states_count}</div>
                                    </div>
                                    <div className='col s8 m2'>
                                        {pais.status?
                                            <div className='cont-textos'><i className="material-icons" style={{fontSize:'14px', color:'green'}}>lens</i> &nbsp;Activo</div>:
                                            <div className='cont-textos'><i className="material-icons" style={{fontSize:'14px', color:'red'}}>lens</i> &nbsp;Inactivo</div>
                                        } 
                                    </div>

                                    <div className='col s12 m2 movil-oculto'>
                                        <div className='cont-textos row m-p-0'>
                                            <div className='col s4 m4 m-p-0 centrar'>
                                                <Link to={`${pais.id}`}
                                                className="m-p-0 centrar">
                                                    <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                                </Link>
                                            </div>
                                            <div className='col s4 m4 m-p-0 centrar'>
                                                <Link to={`${pais.id}/estados`}
                                                className="col s4 m4 m-p-0 centrar">
                                                    <i className="material-icons m-p-0" style={{fontSize:'20px', color:'#5e72e4'}}>library_books</i>
                                                </Link>
                                            </div>
                                            <div className='col s4 m4 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(pais.id)}>delete_forever</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>  
                            <Pagination 
                                currentPage={currentPage}
                                skip={skip}
                                totalItems={paises.length}  
                                itemsPerPage={itemsPerPage}
                                setItemsPerPage={setItemsPerPage}   
                                paginate={paginate}
                            />
                        </>
                       :
                       <NoPermissions />
                    }
                    </div>
                </div>
             

           {/** Modal Nuevo Pais*/ }
            <div id="new-country" className="modal">
                <form onSubmit={_savePais} id="general-form">
                    <div className="modal-content">
                        <h5>Nuevo País</h5>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">País</label>
                                <input id="name" type="text" className="validate" value={data.description} 
                                onChange={(e)=>setData({...data,description:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className='col s12 p-t-35'>
                            <div className="btn waves-effect waves-light" onClick={_closemodal}>Cancelar</div>
                            <button className="btn waves-effect waves-light modal-close" type="submit" name="action">Guardar
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Paises;