import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import '../Admin/admin.scss';
import MenuLeft from '../MenuLeft/MenuLeft';
import TopMenu from '../TopMenu/TopMenu';

const Admin = () => {
    //console.log("ADMIN")
    const [wMenuLateral, setWMenuLateral] = useState('15%');
    const [wContainerAdmin, setWContainerAdmin] = useState('85%');
    const [colapsed, setColapsed] = useState(false)
    return (
        <div className='bg-container-admin'>
            <div className='row m-p-0'>
                <div className='col cont-menu-lateral' style={{width:wMenuLateral, overflowY:'hidden', zIndex:99}}>
                    <MenuLeft colapsed={colapsed} 
                    setColapsed={setColapsed} 
                    setWContainerAdmin={setWContainerAdmin} 
                    setWMenuLateral={setWMenuLateral} />
                </div>
                <div className='col con-container-gral m-p-0' style={{width:wContainerAdmin}}>
                    <TopMenu/>
                    <div className='row m-p-0'>
                        <Outlet /> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admin;