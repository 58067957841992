import React, {useContext, useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import axios from '../../axios';
import M from 'materialize-css';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import { UserContext } from '../../UserContext';
import Loader from '../../Layout/Loaders/Loader';

const Edit = () => {
    let {especialidadId} = useParams();
    const [especialidad, setEspecialidad] = useState({especialidad:'',notas:''})
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [permisos,setPermisos] = useState({
        doctor_specialties_read:null,
        doctor_specialties_update:null
    });
    const [validate, setValidate] = useState(true);
    const [loaded,setLoaded] = useState(false);

    useEffect(()=>{
        if(user.user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                doctor_specialties_update:checkPermiso("doctor_specialties_update",user.user.permissions),
                doctor_specialties_delete:checkPermiso("doctor_specialties_delete",user.user.permissions)
            }));

            axios.get(`api/specialties/${especialidadId}`)
            .then((res)=>{
                setEspecialidad(res.data)
            }).catch(e=>console.log(e))
        }
    },[especialidadId,user])

    useEffect(()=>{
        (permisos.doctor_specialties_read !==null) && setLoaded(true)
    },[permisos]);

    useEffect(()=>{
        axios.get(`api/specialties/${especialidadId}`)
        .then(res=>{
            console.log(res.data)
            setEspecialidad(res.data)
        }).catch(e =>{console.log(e, 'Error ')})
    },[especialidadId])

    const _update = (e)=>{
        e.preventDefault();
        axios.put(`api/specialties/${especialidadId}`,especialidad)
        .then((res)=>{
            console.log(res.data)
            setEspecialidad(res.data)
            M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Especialidad actualizada exitosamente</span>',classes:"success-notify"})
            setTimeout(() => {
                navigate("/admin/especialidades")
            }, 5000);
        }).catch(e=>console.log(e))
    }
        
    return (
        <>
            <div className='container-admin-menu '> <Breadcrumb actual={especialidad.especialidad}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                {loaded ?
                <>
                {permisos.doctor_specialties_read ?
                    <>
                    <h5>Editar especialidad</h5>
                    <form className="row ancho100" id='general-form'  onSubmit={_update}>
                        <div className="col s12">
                            <div className="input-text">
                                <label htmlFor="name">Especialidad</label>
                                <input id="name" type="text" className="validate" value={especialidad.especialidad} 
                                onChange={(e)=>setEspecialidad({...especialidad,especialidad:e.target.value})}/>
                            </div>
                        </div>
                        <div className='col s12'>
                            <div className='input-text'>
                                <label htmlFor='notas'>Notas</label>
                                <textarea id='notas' value={especialidad.notas? especialidad.notas:''}
                                onChange={(e)=>setEspecialidad({...especialidad,notas:e.target.value})}></textarea>
                            </div>
                        </div>
                        <div className='col s12 p-t-35'> 
                            {permisos.doctor_specialties_update &&
                            <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar
                                <i className="material-icons right">send</i>
                            </button>
                            }
                        </div>
                    </form>
                    </>
                        :
                        <NoPermissions />
                        }
                    </>  
                    :
                    <div className='height-200 centrar'>
                        <Loader/>
                    </div>
                }
                </div>
            </div>
          
        </>
    );
};

export default Edit;