import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axios';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import EmpleadoForm from './EmpleadoForm';
import M from 'materialize-css';
import EmpleadoDataForm from './EmpleadoDataForm';
import EmpleadoAddress from './EmpleadoAddress';
import { useContext } from 'react';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const Edit = () => {
    const {empleadoId} = useParams();
    const {user} = useContext(UserContext);
    const [empleado,setEmpleado] = useState();
    const [empleadoData,setEmpleadoData] = useState();
    const [empleadoAddress,setEmpleadoAddress] = useState();
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        employees_read:null,
        employees_update:null,
        employee_addresses_read:null,
        employee_addresses_update:null,
        employee_datas_read:null,
        employee_datas_update:null
    })

    useEffect(()=>{
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                employees_read:checkPermiso("employees_read",user.permissions),
                employees_update:checkPermiso("employees_updater",user.permissions),
                employee_addresses_read:checkPermiso("employee_addresses_read",user.permissions),
                employee_addresses_update:checkPermiso("employee_addresses_updater",user.permissions),
                employee_datas_read:checkPermiso("employee_datas_read",user.permissions),
                employee_datas_update:checkPermiso("employee_datas_updater",user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        const getEmployee = () =>{
            axios.get(`api/employees/${empleadoId}`).then((resp)=>{
                console.log(resp.data);
                setEmpleado(resp.data);
            }).catch(e=>console.log(e));
        }
    
        const getEmployeeData = ()=>{
            axios.get(`api/employee_data/${empleadoId}`).then((resp)=>{
                console.log(resp.data);
                setEmpleadoData(resp.data);
            }).catch(e=>console.log(e));
        }
    
        const getEmployeeAdress = () =>{
            axios.get(`api/employee_address/${empleadoId}`).then((resp)=>{
                console.log("domiclio",resp.data);
                setEmpleadoAddress(resp.data);
            }).catch(e=>console.log(e));
        }

        if(permisos.employees_read !== null){
            setLoaded(true);
            (permisos.employees_read) && getEmployee();
            getEmployeeData();
            getEmployeeAdress();
           
        }
    },[empleadoId, permisos])

   
    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb hasButton={false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                    <div className="row">
                        <div className="col s12">
                            <ul className="tabs" id="empleadotab">
                                <li className="tab col s3"><a className="active" href='#test1'>Datos de Empleado</a></li>
                                <li className="tab col s3"><a   href='#test2'>Datos Laborales</a></li>
                                <li className="tab col s3"><a  href='#test3'>Domicilio del empleado</a></li>
                            </ul>
                        </div>
                        <div id="test1" className="col s12 m-t-10" style={{height:"60vh"}}>
                            {loaded ?
                                permisos.employees_read ?
                                    (empleado && empleado.id)?
                                        <EmpleadoForm 
                                                empleado={empleado} 
                                                setEmpleado={setEmpleado}
                                                permisos={permisos}
                                            />:
                                            <div className="progress">
                                                <div className="indeterminate"></div>
                                            </div>
                                    :
                                    <NoPermissions />
                                :
                                <Loader />
                            }
                        </div>
                        <div id="test2" className="col s12 m-t-10" style={{height:"60vh"}}>
                            {permisos.employee_datas_read ?
                                ((empleadoId) && (empleadoData && empleadoData.id))&&
                                <EmpleadoDataForm 
                                    empleadoId={empleadoId}
                                    empleadoData={empleadoData} 
                                    setEmpleadoData={setEmpleadoData}
                                    permisos={permisos}
                                />
                                :
                                <NoPermissions />
                            }
                        </div>
                        <div id="test3" className="col s12 m-t-10" style={{height:"60vh"}}>
                            {permisos.employee_addresses_read ?
                                ((empleadoId) && (empleadoAddress && empleadoAddress.id))&&
                                    <EmpleadoAddress 
                                        empleadoId={empleadoId} 
                                        empleadoAddress={empleadoAddress } 
                                        setEmpleadoAddress= {setEmpleadoAddress} 
                                        permisos={permisos}
                                    />
                                :
                                <NoPermissions />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;