import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import axios from 'axios';
import M from 'materialize-css';
import NewModeloModal from './NewModeloModal';
import { UserContext } from '../../../UserContext';
import { checkPermiso } from '../../../Utils/PermissionsFunctions';
import NoPermissions from '../../NoPermissions/NoPermissions';
import Loader from '../../../Layout/Loaders/Loader';

const dias=["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

const ModeloCitas = () => {
    const {user} = useContext(UserContext);
    const [sucursales, setSucursales] = useState([]);
    const [diaSeleccionado, setDiaSeleccionado] = useState(1);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState('');
    const [citas, setCitas] = useState([]);
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        appointment_models_create:null,
        appointment_models_list_read:null,
        appointment_models_update:null,
        appointment_models_delete:null
    })
 
    useEffect(() => {        
        axios.get('api/sucursals')
        .then( res=>{
            setSucursales(res.data);
            M.FormSelect.init(document.querySelector('#days'));
            M.FormSelect.init(document.querySelector('#sucursal'));
        }).catch(e =>{console.log(e, 'Error todas las sucursales')})
    },[]);

    useEffect(()=>{
        if(diaSeleccionado > 0 && sucursalSeleccionada > 0) _getCitas();
    },[sucursalSeleccionada, diaSeleccionado]);
    

    useEffect(()=>{
        (permisos.appointment_models_list_read !== null) && setLoaded(true);
    },[permisos]);

    useEffect(()=>{
        //validar permisos
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                appointment_models_create:checkPermiso("appointment_models_create",user.permissions),
                appointment_models_list_read:checkPermiso("appointment_models_list_read",user.permissions),
                appointment_models_update:checkPermiso("appointment_models_update",user.permissions),
                appointment_models_delete:checkPermiso("appointment_models_delete",user.permissions)
            }));
       }
    },[user])

    const _getCitas = ()=>{
        axios.post('api/get_appointment_models',{"sucursal_id":sucursalSeleccionada, "day_id":diaSeleccionado})
        .then(res=>{
                console.log('Las Citas', res.data);
                setCitas(res.data);
            }
        ).catch(e =>{console.log(e, 'Error todas las sucursales')})
    }

    const _renderSucursal = (sucursalId)=>{
        let sucName='';
        sucursales.forEach(suc => {
            if(suc.id == sucursalId){
                sucName = suc.nombre_sucursal;
            }
        });
        return sucName; 
    };

    const _handleChange = (e,cita) =>{
        let value = e.target.value;
        let newCita;
        console.log(e)
        if(e.target.id === "hora"){
             newCita = {...cita,hora:value};
        }else if(e.target.id === "observaciones"){
             newCita = {...cita,observaciones:value};
        }
        const updateCitas = citas.map(c=>c.id === cita.id?newCita:c);
        setCitas(updateCitas);
      
        axios.put(`api/appointment_model/${cita.id}`,newCita)
        .then(resp=>{
            
        }).catch(e=>console.log("Error:",e));
    }

    const _delete = (cita) =>{
        if(window.confirm("Realmente deseas borrar este horario de cita?")) {
            axios.delete(`api/appointment_model/${cita.id}`)
            .then(res=>{
                if(res.status===200){
                    let tempoCita = citas.filter(c=>c.id!==res.data.modelo.id);
                    setTimeout(() => {
                        setCitas(tempoCita);  
                    }, 1000);
                } 
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
               
            }).catch(e=>console.log("error",e))
        }
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb hasModalButton={permisos.appointment_models_create? "modal-new-modelo" : false} hasFilters={false} /> </div>
            <div className='container-admin-container'>
                <div className='container-admin z-depth-1 row'>
                <NewModeloModal 
                    diasSeleccionado={diaSeleccionado} 
                    sucursalSeleccionada={sucursalSeleccionada} 
                    setSucursalSeleccionada={setSucursalSeleccionada} 
                    dias={dias} 
                    sucursales={sucursales}
                    _getCitas={_getCitas}
                    />
                {loaded ?
                    permisos.appointment_models_list_read ?
                    <>
                        {/* DOCTORES HEADER Y FILTROS */}
                        <div className='col s12 info-badge border-r-10 row m-p-0' 
                        style={{bordeRadius:'10px!important', padding:'0px!important', paddingTop:'0px!important',}}>          
                            <div className="col s3 m2 p-0" style={{margin:0, marginTop:'-9px'}}><label>Tipo de Día</label>
                                <div className="input-field col s12 m-p-0">
                                    <div className='btn-blanco m-p-0' id='selectiones'>
                                        <select className='m-0' id='days' onChange={(e)=>setDiaSeleccionado(e.target.value)}
                                        defaultValue={diaSeleccionado}
                                        style={{borderRadius:'10px', margin:'0px!important', padding:'20px!important', border:'solid red 1px'}}>
                                            <option value="0" className='m-0' disabled selected>Seleccione un día</option>
                                            {dias.map((dia, i) =>
                                            <option value={i+1 } key={i} className='m-0' style={{border:'solid lleyow!important'}}>{dia}</option>
                                            )
                                            }
                                            
                                        </select>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col s3 m1 m-p-0"> </div>
                            <div className="col s3 m5 p-0" style={{margin:0, marginTop:'-9px'}}><label>Tipo de Sucursal</label>
                                <div className="input-field col s12 m-p-0">
                                    <div className='btn-blanco m-p-0' id='selectiones'>
                                        <select className='m-0' id='sucursal' defaultValue={sucursalSeleccionada} onChange={(e)=>setSucursalSeleccionada(e.target.value)}
                                        style={{borderRadius:'10px', margin:'0px!important', padding:'20px!important', border:'solid red 1px'}}>
                                            <option value="" className='m-0' disabled selected>Seleccione la sucursal</option>
                                            {sucursales.map( (sucursal)=>
                                            <option value={sucursal.id} key={sucursal.id} className='m-0'>{sucursal.nombre_sucursal}</option>
                                            )}
                                        </select>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div className='m-p-0'>
                            <div className='m-p-10'>
                            {
                                (diaSeleccionado > 0 && sucursalSeleccionada > 0)?

                                <div className='col table table-doc m-p-0' style={{width:'100%'}}>
                                    <div className='table-header row m-t-20 '>
                                        <div className='col s10 m3' style={{textAlign:'left'}}>&nbsp;&nbsp; Sucursal </div>
                                        <div className='col s10 m2 centrar'> Hora </div>
                                        <div className='col s12 m4 centrar'> Observaciones </div>
                                        <div className='col s12 m2 centrar'> Tipo de día </div>
                                        <div className='col s12 m1'> Acciones</div>
                                    </div>
                                    
                                    <div className='table-container'>
                                    {citas.map((cita,index)=>
                                        <div className='row' key={cita.id}>
                                            <div className='col s2 m3'>
                                                <div>&nbsp;&nbsp;
                                                    {_renderSucursal(cita.sucursal_id)}
                                                </div>
                                            </div>
                                            <div className='col s2 m2 p-5'>
                                                <div className="input-field input-header col s12 m-p-0">
                                                    <input type="time" value={cita.hora} id="hora" className="validate centrado m-p-0" onChange={(e)=>_handleChange(e,cita)}/>
                                                    <label className="active label-editar m-p-0" htmlFor="hora">Editar Hora</label>
                                                </div>
                                            </div>
                                            <div className='col s2 m4 p-5'>
                                            <div className="input-field  input-header col s12 m-p-0">
                                                    <input type="text" value={cita.observaciones} id="observaciones" className="validate centrado m-p-0" onChange={(e)=>_handleChange(e,cita)} disabled={permisos.appointment_models_update}/>
                                                    {!permisos.appointment_models_update &&
                                                    <label className="active label-editar" htmlFor="observaciones">Editar</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col s2 m2 centrar '>
                                                <div className='centrado'>{dias[cita.day_id - 1]}</div>
                                            </div>
                                            <div className='col s12 m1 movil-oculto'>
                                            {permisos.appointment_models_delete &&
                                                <div className='row centrado cont-textos'>
                                                    <div className='col m6 m-p-0 centrar movil-100'>
                                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'red',cursor:'pointer'}} 
                                                        onClick={(e)=>_delete(cita)}>delete_forever</i>
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    )}
                                        
                                
                                    </div>    
                                </div>

                                :

                                <div className='col m12 grey lighten-5 border-r-10' style={{padding:5, }}>
                                    <div className='font12 negritas p-5'> Modelo de Cita para el tipo de día seleccionado y la sucursal seleccionada</div>
                                </div>
                            }
                            
                            </div>
                        </div>
                    </>
                    :
                    <NoPermissions></NoPermissions>
                :
                <Loader />
                }
                </div>
            </div>
        </div>
    );
};

export default ModeloCitas;