import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../Sucursales/sucursales.scss'
import M from 'materialize-css';
import axios from '../../axios';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Spinner from '../../Layout/Loaders/Spinner';
import NoData from '../../Layout/Notificaciones/NoData';

const Sucursales = () => {
    const user = useContext(UserContext);
    const [sucursales, setSucursales] = useState([]); 
    const [loaded,setLoaded] = useState(false);
    const [permisos,setPermisos] = useState({
        sucursals_create:null,
        sucursals_read:null,
        sucursals_update:null,
        sucursals_delete:null
    })

    useEffect(() => {    
        console.log("user",user)    
        if(user.user.id){
            getSucursals();
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                sucursals_create:checkPermiso("sucursals_create",user.user.permissions),
                sucursals_read:checkPermiso("sucursals_read",user.user.permissions),
                sucursals_update:checkPermiso("sucursals_update",user.user.permissions),
                sucursals_delete:checkPermiso("sucursals_delete",user.user.permissions)
            }));
        }
    },[user])

    useEffect(()=>{
        (permisos.sucursals_read !==null) && setLoaded(true);
    },[permisos])

    const getSucursals = () =>{
        axios.get('api/sucursals')
        .then( res=>{
            console.log(res.data, 'todas las sucursales')
            var elems = document.querySelectorAll('.tooltipped');
            M.Tooltip.init(elems, {position:"top"});
            setSucursales(res.data);
        }).catch(e =>{console.log(e, 'Error todas las sucursales')})
    }

    const _delete = (sucursalId) =>{
        if(window.confirm("Realmente deseas borrar a esta sucursal, si lo borras no se podra recuperar despues")) {
            axios.delete(`api/sucursals/${sucursalId}`)
            .then(res=>{
                M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>'+res.data.message+'</span>',classes:"success-notify"})
                console.log("Eliminado",res.data.sucursal.id)
                setSucursales(sucursales.filter(u=>u.id!==res.data.sucursal.id))
            }).catch(e=>{
                console.log("error",e)
                if(e.response.status === 500 && e.response.data.message.search("Integrity constraint violation")){
                    M.toast({html:'<i class="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>No puedes borrar una sucursal que esta vinculada con otros datos</span>',classes:"error-notify"})
                }
            })
        }
    }
    return (
        
    <>
        <div className='container-admin-menu '> <Breadcrumb hasButton={permisos.sucursals_create} hasFilters={false} /> </div>
        <div className='container-admin-container'>
            <div className='container-admin z-depth-1'>
            {loaded ?
                permisos.sucursals_read ?
                <div className='table'>
                    <div className="table-item-counter">Total sucursales: {sucursales.length}</div>
                    <div className='table-header row movil-oculto'>
                        <div className='col s2 m1'> ID </div>
                        <div className='col s10 m2'> Sucursal </div>
                        <div className='col s12 m4'> Domicilio</div>
                        <div className='col s12 m2'> Telefonos</div>
                        <div className='col s12 m1'> Horarios</div>
                        <div className='col s12 m1'> Entregas</div>
                        <div className='col s12 m1 t-right'> Acciones </div>
                    </div>
                    {sucursales.length > 0 ? sucursales.map( (sucursal)=>
                        <div className='table-container row' key={sucursal.id}>
                            <div className='col s8 m1 movil-oculto'>
                                <div className='cont-textos'>{sucursal.id}</div> 
                            </div>
                            <div className='col s12 movil-visible'>
                                <div className='col s6 table-header-movil row m-p-0' style={{borderTop:'solid 1px #11cdef'}}> 
                                    <div className='col s6 m6 m-p-0' style={{padding:'0!important'}}>ID <div className='cont-textos'>{sucursal.id}</div> </div>
                                    <div className='col s6 m6 m-p-0'  style={{padding:'0!important'}}>
                                        <div className='cont-textos row'>
                                            {permisos.sucursals_update&&
                                            <Link to={`${sucursal.id}`} style={{color:'green'}}
                                            className="col s6 m6 push-m3 m-p-0 centrar">
                                                <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px',}}>create</i>
                                            </Link>
                                            }
                                            {permisos.sucursals_delete&&
                                            <div className='col s6 m6 push-m3 m-p-0 centrar'>
                                                <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(sucursal.id)}>delete_forever</i>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Sucursal </div>
                            </div>
                            <div className='col s8 m2'> <div className='cont-textos'>{sucursal.nombre_sucursal}</div></div>
                            <div className='col s4 movil-visible'>
                                <div className='table-header-movil'>Domicilio </div>
                            </div>
                            <div className='col s8 m4'> <div className='cont-textos'>{sucursal.domicilio}</div> </div>
                            <div className='col s4 m4 movil-visible'>
                                <div className='table-header-movil'>Telefonos </div>
                            </div>
                            <div className='col s8 m2' style={{display:'inline!important'}}> 
                                <div style={{fontSize:12, margin:0, padding:'0', width:'100%'}}>
                                    <div><i className="material-icons" style={{fontSize:13, fontWeight:800, color:'#525f7f'}}>local_phone</i> &nbsp; 
                                    <span style={{paddingTop:0, fontWeight:500}}>{sucursal.telefono1}</span></div>
                                    <div><i className="material-icons" style={{fontSize:13, fontWeight:800, color:'#525f7f'}}>local_phone</i> &nbsp; 
                                    <span style={{paddingTop:0, fontWeight:500}}>{sucursal.telefono2}</span></div>
                                    <div><i className="material-icons" style={{fontSize:13, fontWeight:800, color:'#525f7f'}}>local_phone</i> &nbsp; 
                                    <span style={{paddingTop:0, fontWeight:500}}>{sucursal.telefono3}</span></div>
                                </div>
                            </div>
                            
                            <div className='col s4 m4 movil-visible'>
                                <div className='table-header-movil'>Horario</div>
                            </div>
                            <div className='col s8 m1'>  
                                <button className='btn-info tooltipped m-t-10' data-tooltip={sucursal.horario}>
                                    <i className="tiny material-icons centrar">schedule</i>
                                </button> 
                            </div>

                            <div className='col s4 m4 movil-visible'>
                                <div className='table-header-movil'>Entregas</div>
                            </div>
                            <div className='col s8 m1'>  
                                <button className='btn-info tooltipped m-t-10' data-tooltip={sucursal.horario_entregas}>
                                    <i className="tiny material-icons centrar">schedule</i>
                                </button> 
                            </div>

                            <div className='col s12 m1 movil-oculto'>
                                <div className='row cont-textos'>
                                    {permisos.sucursals_update&&
                                        <Link to={`${sucursal.id}`} style={{color:'green'}}
                                        data-tooltip="Editar"
                                        className="col m6 m-p-0 centrar movil-100 tooltipped" >
                                            <i className="material-icons m-p-0 t-azul" style={{fontSize:'20px'}}>create</i>
                                        </Link>
                                    }
                                    {permisos.sucursals_delete&&
                                    <div className='col m6 m-p-0 centrar movil-100'>
                                        <i className="material-icons m-p-0" style={{fontSize:'20px',color:'#a12312',cursor:'pointer'}} onClick={(e)=>_delete(sucursal.id)}>delete_forever</i>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    :
                    <NoData modulo="Sucursales"></NoData>
                    }
                    
                </div>
                :
                <NoPermissions />
            :
            <div className='height-200 centrar'>
                <Spinner size={"medium"} />
            </div>
            }
            </div>
        </div>
                
    </>
    
    );
};

export default Sucursales;