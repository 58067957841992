import React, { useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from 'axios';

const VisitaFormulario = (props) => {
    console.log('props', props.doctor_offices);
    const [visita,setVisita] = useState({doctor_id:props.doctorId,fecha_visita:'',hora:'',causa_visita:'',observaciones:'',representante:'',direccion:''});
    const causasVisita = ['Normal','Solicito','Queja','Papeleria','Otro'];
    const [empleado,setEmpleados] = useState([]);
    const [consultorioSelected, setConsultorioSelected] = useState({id:0});
    const [consultorios, setConsultorios] = useState([]);
    const [otros, setOtros] = useState(false);

    useEffect(()=>{
        M.FormSelect.init(document.querySelector('#select-causas'));
    },[])

    useEffect(()=>{
        if(props.doctor_offices.length === 0 && props.doctorId > 0){
            axios.get(`api/all_doctor_offices/${props.doctorId}`).then((resp)=>{
                setConsultorios(resp.data);
                M.FormSelect.init(document.querySelector('#select-consultorios'));
            }).catch(e=>console.log("Error:",e))
        }
        else{
            setConsultorios(props.doctor_offices);
            M.FormSelect.init(document.querySelector('#select-consultorios'));
        }
    },[props.doctorId, props.doctor_offices])

    useEffect(()=>{
        axios.get('api/employees').then((resp)=>{
            setEmpleados(resp.data);
            M.FormSelect.init(document.querySelector('#select-representante'));
        }).catch(e=>console.log("Error:",e))
    },[])
    
    useEffect(()=>{
        setVisita({doctor_id:props.doctorId,fecha_visita:'',hora:'',causa_visita:'',observaciones:'',representante:'',direccion:''})
    },[props.doctorId])
    
    const _agendar = () =>{
        console.log("visita1",visita)
        axios.post('api/visits',visita).then((response)=>{
            console.log(response.data)
            setVisita({doctor_id:props.doctorId,fecha_visita:'',hora:'',causa_visita:'',observaciones:'',representante:'',direccion:''})
            if(props.setNewVisita){
                props.setNewVisita(true)
            }
            console.log('visita2', visita);
        }).catch((e)=>{
            console.log(e)
        })
    }

    const _handleConsultorio = (e) =>{
        if(e.target.value == 'otros'){
            setOtros(true);
            setConsultorioSelected({id:0});
        }
        else{
            let consultorioTemp = consultorios.find(f => f.id == e.target.value );
            console.log('consultorioTemp', consultorioTemp);
            setConsultorioSelected(consultorioTemp);
            setOtros(false);
            let direccion = consultorioTemp.direccion+','+consultorioTemp.colonia+','+consultorioTemp.municipio+' Horario:'+consultorioTemp.horario;
            setVisita({...visita,direccion:direccion})
        }
    }

    return (
        <>
            <h5 className='p-5'>Visitar doctor</h5>
            <div className="row cont-contain "  id='general-form' style={{background:'#e8f0fe', borderRadius:'12px'}}>
                <div className="col s12 m12"> 
                    <div className="col s12 m4">
                        <div className="input-text"><br></br>
                            <label htmlFor="name">Selecciona el Consultorio donde se hara la visita</label>
                        </div>
                    </div>
                    <div className="col s12 m8">
                        <div className="input-text"><br></br>
                            <hr style={{borderTop:'solid #11cdef 1px'}}></hr> 
                        </div>
                    </div>
                </div>
                <div className="col s12 m12"> <hr style={{borderTop:'solid #11cdef 1px'}}></hr> </div>
                <div className="col s12 m6">
                    <div className="input-text">
                        <label htmlFor="name">Consultorio</label>
                        <select id="select-consultorios" onChange={(e)=>_handleConsultorio(e)}  style={{background:'white'}} >
                            <option value="" disabled selected >Consultorio</option>
                            {consultorios.map((consultorio,i)=>
                                <option value={consultorio.id} key={i}> {consultorio.nombre} </option>
                            )}
                            <option style={{background:'#ffffff'}} value="otros" >Otro</option>
                        </select>
                    </div>
                </div>
                <div className="col s12 m12">
                    {otros &&
                    <div className="input-text">
                        <label htmlFor="name">Anotaciones</label>
                        <textarea id="otros" type="text" style={{background:'#ffffff', minHeight:'70px'}} 
                        onChange={(e)=>setVisita({...visita,direccion:e.target.value})}></textarea>
                    </div>
                    }
                </div>
                
                <div className="col s12 m12" style={{padding:'0 20px 20px 20px', paddingTop:'0px!important', marginTop:'-10px!important'}}>
                {consultorioSelected.id > 0 &&
                    <div className="col s12 m12 sombra-azul-chica" style={{backgroundColor:'#11cdef', color:'#000', padding:'10px', paddingTop:'0px!important', marginTop:'-10px!important',}}>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="name" style={{color:'blue'}}>Direccion</label>
                                <div><b> {consultorioSelected.direccion} </b></div>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="name" style={{color:'blue'}}>Colonia</label>
                                <div><b> {consultorioSelected.colonia} </b></div>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="name" style={{color:'blue'}}>Municipio</label>
                                <div><b> {consultorioSelected.municipio} </b></div>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="input-text">
                                <label htmlFor="name" style={{color:'blue'}}>Horario</label>
                                <div><b> {consultorioSelected.horario} </b></div>
                            </div>
                        </div>
                    </div>
                }
                </div>
                <div className="col s12 m6">
                    <div className="input-text">
                        <label htmlFor="name">Fecha</label>
                        <input id="name" type="date" style={{background:'#ffffff'}} 
                        onChange={(e)=>setVisita({...visita,fecha_visita:e.target.value})}
                        />
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className="input-text">
                        <label htmlFor="hora">Hora</label>
                        <input id="hora" type="time"   style={{background:'#ffffff'}} 
                        onChange={(e)=>setVisita({...visita,hora:e.target.value})}
                        />
                    </div>
                </div>
                <div className="col s12 m12"> <hr style={{borderTop:'solid #11cdef 1px'}}></hr> </div>
                <div className="col s12 m6">
                    <div className="input-text">
                        <label htmlFor="name">Representante</label>
                        <select id="select-representante" onChange={(e)=>setVisita({...visita,representante:e.target.value})}
                        style={{border:'solid red'}}>
                            <option style={{background:'#ffffff'}} value="" disabled selected >Representante</option>
                            {empleado.map((empleado,i)=>
                                <option style={{background:'#ffffff'}} value={empleado.nombre + " " + empleado.apellidos} key={i}>{empleado.nombre} {empleado.apellidos}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className="input-text">
                        <label>Causa de visita</label>
                        <select id="select-causas" onChange={(e)=>setVisita({...visita,causa_visita:e.target.value})}  
                        style={{background:'#ffffff'}}>
                            <option value="" style={{background:'#ffffff'}} disabled selected >Causa de la visita</option>
                            {causasVisita.map((causa,i)=>
                                <option style={{background:'#ffffff'}} value={causa} key={i}>{causa}</option>
                            )}
                        </select>
                    
                    </div>
                </div>
                <div className="col s12 m12"> <hr style={{borderTop:'solid #11cdef 1px'}}></hr> </div>
                <div className="col s12 m12">
                    <div className="input-text">
                        <label htmlFor="observa4">Observaciones de la visita</label>
                        <textarea id="observa4" 
                            style={{background:'#ffffff',paddingLeft:10}} 
                        className="materialize-textarea validate"
                        onChange={(e)=>setVisita({...visita,observaciones:e.target.value})}></textarea>
                    </div>
                </div>
                <button className='btn-info right m-10' onClick={(e)=>_agendar()}>AGENDAR VISITA</button>
            </div>
        </>
    );
};

export default VisitaFormulario;