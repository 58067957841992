import React, { useEffect, useState } from 'react';
import M from 'materialize-css'
import axios from 'axios';
import PermisosTable from './PermisosTable';

const PermisosCollapsible = (props) => {
    const {permisosSelected ,setPermisosSelected} = props;
    const [allPermissions, setAllPermissions] = useState([]);
    const [permisosFiltrados, setPermisosFiltrados] = useState([]);

    useEffect(()=>{
        var elems = document.querySelector('#permisos-collapsible');
        M.Collapsible.init(elems,{});
        axios.get(`api/get_all_permissions`).then((resp)=>{
           
            setAllPermissions(resp.data);
            let tempPermissions = [];
             
            resp.data.map((item)=>{
               var i = tempPermissions.findIndex((ele)=>ele.module === item.module);
                if(i <= -1){
                    tempPermissions.push({
                        module:item.module,
                        permisions:filtrarPermisos(item.module, resp.data)
                    })
                
                }
                else return null;
            });
            setPermisosFiltrados(tempPermissions);
        }).catch(e=>console.error("Error ",e));
    },[]);

    const filtrarPermisos = (module, data)=>{
       var items = data.filter((item)=>item.module === module);
      
       return items;
    };

    return (
        <div>
            <ul class="collapsible" id="permisos-collapsible">
            {permisosFiltrados.map((modulo,index)=>
                <li className='m-p-0'>
                    <div class="collapsible-header m-p-0">
                        <div className='col s12 m-p-0' style={{position:"relative"}}>
                            <h6>
                                <blockquote className='m-p-0'>
                                <div class=""><i class="material-icons">lock</i> {modulo.module} </div>
                                </blockquote>
                            </h6>
                            <button data-target="modaladdress" 
                            className="btn-floating pulse cyan modal-trigger centrar m-p-0" 
                            style={{position:'absolute',top:10,right:15, width:28, height:28}}>
                                <i className="material-icons" style={{marginLeft:14,marginBottom:14, width:28, height:26}}>add</i>
                            </button>
                        </div>
                    </div>
                    <div class="collapsible-body m-p-0">
                        <div className='row m-0 p-5'>
                            <PermisosTable permisos={modulo.permisions} permisosSelected={permisosSelected} setPermisosSelected={setPermisosSelected}/>
                        </div>
                    </div>
                </li>
            )}    
            </ul>                
        </div>
    );
};

export default PermisosCollapsible;