import React, { useContext, useEffect, useState } from 'react';
import M from 'materialize-css';
import axios from '../../axios';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { UserContext } from '../../UserContext';
import { checkPermiso } from '../../Utils/PermissionsFunctions';
import NoPermissions from '../NoPermissions/NoPermissions';
import Loader from '../../Layout/Loaders/Loader';

const Detail = () => {
    const {empleadoId} = useParams();
    const {user} = useContext(UserContext);
    const [empleado, setEmpleado] = useState([]);
    const [empleadoData, setEmpleadoData] = useState([]);
    const [empleadoAdres, setEmpleadoAdres] = useState([]);
    const [loaded,setLoaded] = useState(false);

    const [permisos,setPermisos] = useState({
        employees_read:null,
        employee_addresses_read:null,
        employee_datas_read:null,
    });

    useEffect(()=>{
        if(user.id){
            setPermisos((prevPremisos) => ({
                ...prevPremisos,
                employees_read:checkPermiso("employees_read",user.permissions),
                employee_addresses_read:checkPermiso("employee_addresses_read",user.permissions),
                employee_datas_read:checkPermiso("employee_datas_read",user.permissions)
            }));
        }
    },[user]);

    useEffect(()=>{
        (permisos.employees_read !== null) && setLoaded(true);
    },[permisos])

    useEffect(()=>{
        axios.get(`api/employees/${empleadoId}`).then((resp)=>{
            console.log("emplado",resp.data);
            setEmpleado(resp.data);
            var elems = document.querySelector('#empleado-collapsible');
            M.Collapsible.init(elems,{});
        }).catch(e=>console.error("Error ",e));
    },[empleadoId]);
    
    useEffect(()=>{
        axios.get(`api/employee_data/${empleadoId}`).then((resp)=>{
            console.log("empladoData",resp.data);
            setEmpleadoData(resp.data);
            
        }).catch(e=>console.error("Error ",e));
    },[empleadoId]);

    useEffect(()=>{
        axios.get(`api/employee_address/${empleadoId}`).then((resp)=>{
            console.log("empladoAdress",resp.data);
            setEmpleadoAdres(resp.data);
            
        }).catch(e=>console.error("Error ",e));
    },[empleadoId]);

    return (
    <div>
        <div className='container-admin-menu '> <Breadcrumb/> </div>
        <div className='container-admin-container'>
            <div className='container-admin  z-depth-1'>
                <h5>Detalles del Empleados</h5>

                <ul className="collapsible" id="empleado-collapsible">
                  
                    <li className="active">
                        <div className="collapsible-header  m-p-0">
                            <div className='col s12 m-p-0' style={{position:"relative"}}>
                                <h6><blockquote>Datos de empleado</blockquote></h6>
                                <button data-target="modaladdress" className="btn-floating pulse cyan modal-trigger  m-p-0" style={{position:'absolute',top:5,right:20}}>
                                    <i className="material-icons m-p-0">add</i>
                                </button>
                            </div>
                        </div>
                        <div className="collapsible-body ">
                            {loaded ?
                                permisos.employees_read ?
                                    <div className='row  m-p-0'>
                                        <div className='col m12  grey lighten-5' style={{padding:5,}}>
                                            <div className='row m-5'>
                                                <div className='col s12 m3 m-t-20'>
                                                    <label>ID Empleado</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999", }}>perm_identity</i>
                                                        <span>{empleado.id}</span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m3 m-t-20'>
                                                    <label>Codigo</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>code</i>
                                                        <span>{empleado.codigo}</span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m3 m-t-20'>
                                                    <label>Fecha de Aplicación</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>date_range</i>
                                                        <span>{empleado.fecha_aplicacion}</span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m3 m-t-20'>
                                                    <label>Fecha de baja</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>perm_contact_calendar</i>
                                                        <span>{empleado.fecha_baja}</span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m4 m-t-20'>
                                                    <label>Nombre(s) de Empleado</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>perm_identity</i>
                                                        <span> {empleado.nombre} </span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m8 m-t-20'>
                                                    <label>Apellidos de Empleado</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>perm_identity</i>
                                                        <span> {empleado.apellidos} </span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m4 m-t-20'>
                                                    <label>Cargo a desempeñar</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person_pin</i>
                                                        <span>{empleado.cargo}</span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m4 m-t-20'>
                                                    <label>Jefe a cargo</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person_add</i>
                                                        <span>{empleado?.jefe?.nombre + " " + empleado?.jefe?.apellidos}</span>  
                                                    </div>
                                                </div>
                                                <div className='col s12 m4 m-t-20'>
                                                    <label>Sucursal asignada</label>
                                                    <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                        <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person_pin_circle</i>
                                                        {<span>{empleado?.sucursal?.nombre_sucursal}</span> }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12 m4 m-t-20'>
                                            <label>Iniciales de Empleado</label>
                                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>perm_identity</i>
                                                <span>{empleado.iniciales}</span>  
                                            </div>
                                        </div>
                                        <div className='col s12 m4 m-t-20'>
                                            <label>Tipo de Empleado</label>
                                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>perm_identity</i>
                                                { <span>{empleado?.employee_type?.descripcion}</span>   }
                                            </div>
                                        </div>
                                        <div className='col s12 m4 m-t-20'>
                                            <label>Email de Empleado</label>
                                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>contact_mail</i>
                                                <span>{empleado.email}</span>  
                                            </div>
                                        </div> 
                                        <div className='col s12 m4 m-t-20'>
                                        <label>Telefono Particular de Empleado</label>
                                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>contacts</i>
                                                <span>{empleado.telefono}</span>  
                                            </div>
                                        </div>
                                        <div className='col s12 m4 m-t-20'>
                                            <label>Telefono Celular de Empleado</label>
                                            <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                                <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>contact_phone</i>
                                                <span>{empleado.celular}</span>  
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <NoPermissions />
                                :
                                <Loader />
                            }
                        </div>
                    </li>
                    
                    <li>
                        <div className="collapsible-header m-p-0">
                            <div className='col s12  m-p-0' style={{position:"relative"}}>
                                <h6><blockquote>Datos Laborales</blockquote></h6>
                                <button data-target="modaladdress" className="btn-floating pulse cyan modal-trigger" style={{position:'absolute',top:5,right:20}}>
                                    <i className="material-icons m-p-0">add</i>
                                </button>
                            </div>
                        </div>
                        <div className="collapsible-body">
                            {permisos.employee_datas_read ?
                                <div className='row'>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>IMSS de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>verified_user</i>
                                            <span>{empleadoData.imss}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>SAR de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>tab_unselected</i>
                                            <span>{empleadoData.sar}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>RFC de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>tab</i>
                                            <span>{empleadoData.rfc}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Fecha de alta al IMSS de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>today</i>
                                            <span>{empleadoData.fecha_alta_imss}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Fecha de Nacimiento de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>perm_contact_calendar</i>
                                            <span>{empleadoData.fecha_nacimiento}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Lugar de Nacimiento de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>place</i>
                                            <span>{empleadoData.lugar_nacimiento}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m6 m-t-20'>
                                        <label>Nombre del Padre</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>people_outline</i>
                                            <span>{empleadoData.nombre_padre}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m6 m-t-20'>
                                        <label>Nombre de la Madre</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>people</i>
                                            <span>{empleadoData.nombre_madre}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Sueldo mensual de Empleado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>monetization_on</i>
                                            <span>{empleadoData.sueldo_mensual}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m8 m-t-20'>
                                        <label>Horario de trabajo</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>schedule</i>
                                            <span>{empleadoData.horario}</span>  
                                        </div>
                                    </div>
                                </div>
                                : 
                                <NoPermissions />
                            }
                        </div>
                    </li>
                    <li>
                        <div className="collapsible-header  m-p-0">
                            <div className='col s12 m-p-0' style={{position:"relative"}}>
                                <h6><blockquote>Direccion de empleado</blockquote></h6>
                                <button data-target="modaladdress" className="btn-floating pulse cyan modal-trigger" style={{position:'absolute',top:5,right:20}}>
                                    <i className="material-icons m-p-0">add</i>
                                </button>
                            </div>
                        </div>
                        <div className="collapsible-body">
                            {permisos.employee_addresses_read ?
                                <div className='row m-p-0'>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Direccion</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>my_location</i>
                                            <span>{empleadoAdres.direccion}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>C.P.</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>nature_people</i>
                                            <span>{empleadoAdres.cp}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Entre calles</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>phone_android</i>
                                            <span>{empleadoAdres.entre_calles}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Telefono</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>phone_android</i>
                                            <span>{empleadoAdres.telefono}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Estado</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>pin_drop</i>
                                            <span>{empleadoAdres.estado}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Municipio</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>place</i>
                                            <span>{empleadoAdres.municipio}</span>  
                                        </div>
                                    </div>
                                    <div className='col s12 m4 m-t-20'>
                                        <label>Colonia</label>
                                        <div className='centrar-l' style={{border:"solid #DEE2E6 1.5px",borderRadius:"4px",height:45,padding:5,width:"100%"}}>
                                            <i className="large material-icons" style={{fontSize:24,paddingRight:5,color:"#999999"}}>person_pin_circle</i>
                                            <span>{empleadoAdres.colonia}</span>  
                                        </div>
                                    </div>
                                </div>
                                :
                                <NoPermissions />
                            }
                        </div>
                    </li>
                </ul>
               
            </div>
        </div>
    </div>
    );
};

export default Detail;