import React from 'react';

const PermisosTable = (props) => {
    const {permisos,permisosSelected,setPermisosSelected} = props;

    const handlePermiso = (event) => {
        console.log("permisos seleccionados",permisosSelected);
        let checked = event.target.checked;
        let permiso = event.target.value;
        if(checked){
            setPermisosSelected(prevArray => [...prevArray, permiso])
        }else{
            let temporal = permisosSelected.filter(p=>p!==permiso);
            setPermisosSelected(temporal);
        }
    }

    return (
    <>   
        {permisos.map((permiso)=>
        <div className='table col s12 m-0 p-1-5'>
            <div className='table-header row m-p-0 movil-oculto'>
                <div className='col s12 m3 m-p-0'> Permiso</div>
                <div className='col s12 m7 m-p-0'> Descripcion</div>    
                <div className='col s12 m2 m-p-0 centrar'> Activar</div>       
            </div> 
            <div className='table-container row'>
                <div className='col s12 m3'>
                    <span className='m-p-0' style={{width:'34px',marginTop:5}}>
                        <img className='dentometric m-p-0' src='/images/rol4.png' alt='dento metric' 
                        style={{height:'20px', width:'18px'}} />
                    </span> 
                    <span className='m-p-0'>{permiso.name}</span>
                </div>
                <div className='col s12 m7 p-0-5'> {permiso.description}</div>
                <div className='col s12 m2 m-p-0'> 
                    <div className="col s12 m12 row m-p-0 centrar" style={{padding:'0px!important', margin:'0px!important',}}>
                        <div class="switch  m-p-0">
                            <label className=' m-p-0'>NO
                                <input type="checkbox" value={permiso.name}  onChange={(e)=>handlePermiso(e)}/>
                                <span class="lever"></span>SI
                            </label>
                        </div>
                    </div>
                </div>  
            </div>                    
        </div> 
        )}
    </> 
    );
};

export default PermisosTable;