import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import M from 'materialize-css';
import { UserContext } from '../../UserContext';

const Edit = () => {
    let {productoId} = useParams();
    const user = useContext(UserContext);
    const [servicio, setServicio] = useState({nombre_producto:"", precio_unidad:"", duracion_aproximada:"", activo:""});
    const navigate = useNavigate();
    const [permisos,setPermisos] = useState({
        category_product_update:null
    });


    useEffect (() =>{
        console.log("USER",user);
        axios.get(`api/products/${productoId}`,servicio)
        .then(res=>{
            setServicio(res.data);
            console.log(res.data, "editar servicio");
        }).catch(e =>{console.log(e, 'Error ')})
    },[])
    
    const _editProducto = (e) =>{
        e.preventDefault();
        console.log("Producto update..",servicio)
        axios.put(`api/products/${productoId}`,servicio).then(resp=>{
            console.log(resp.data)
            M.toast({
                html:'<i className="material-icons">insert_emoticon</i> &nbsp;&nbsp;<span>Producto/Servicio actualizado exitosamente</span>',
                classes:"success-notify"
            })
            setTimeout(() => {
                navigate("/admin/servicios")
            }, 3000);
        }).catch(e=>console.log(e))
    }

    return (
        <div>
            <div className='container-admin-menu '> <Breadcrumb actual={servicio.nombre_sucursal}/> </div>
            <div className='container-admin-container'>
                <div className='container-admin'>
                    <h5>Editar Servicios/ Productos</h5>
                    <form  onSubmit={_editProducto} className="row ancho100" id='general-form'>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="name">Nombre *</label>
                                <input id="name" type="text" className="validate" value={servicio.nombre_producto} 
                                onChange={(e)=>setServicio({...servicio,nombre_producto:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div className="input-text">
                                <label htmlFor="precio">Precio *</label>
                                <input id="precio" type="number" 
                                pattern="[0-9]" maxlength="10" step="0.1"
                                className="validate" value={servicio.precio_unidad} 
                                onChange={(e)=>setServicio({...servicio,precio_unidad:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="input-text">
                                <label htmlFor="horario">Duracion Aproximada en Minutos *</label>
                                <input id="horario" type="number" 
                                step="any" maxlength="10"
                                className="validate" value={servicio.duracion_aproximada} 
                                onChange={(e)=>setServicio({...servicio,duracion_aproximada:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="input-text">
                                <label htmlFor="entrega">Duracion de entrega</label>
                                <input id="entrega" type="number" 
                                step="any" maxlength="10"
                                className="validate" value={servicio.dias_entrega} 
                                onChange={(e)=>setServicio({...servicio,dias_entrega:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="input-text">
                                <label htmlFor="preparacion">Días de preparacion</label>
                                <input id="preparacion" type="number" 
                                step="any" maxlength="10"
                                className="validate" value={servicio.dias_preparacion} 
                                onChange={(e)=>setServicio({...servicio,dias_preparacion:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col s6 m4 centrar" style={{minHeight:90}}>       
                            <div className="switch input-text">
                                <label>
                                Activo
                                <input id="activo" type="checkbox" checked={servicio.activo} onChange={(e)=>setServicio({...servicio,activo:e.target.checked})}/>
                                <span className="lever"></span>
                                </label>
                            </div>
                        </div>

                        <div className='col s12 m12 p-t-35 centrar-r'> 
                            <button className="btn waves-effect waves-light" type="submit" name="action">Actualizar datos
                                <i className="material-icons right">save</i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Edit;