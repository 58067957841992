import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import '../Login/login.scss'
import axios from '../../axios';
import M from 'materialize-css';

const Login = () => {
    const {setUser,setAuth} = useContext(UserContext)
    const navigate = useNavigate();
    const [data, setData] = useState({email:'', password:''})

    useEffect(()=>{
        console.log("El login")
        axios.get('/api/user')
        .then(res=> {
            console.log("resUser", res )
            setAuth(true);
            setUser(res.data)
        }).catch( e => {console.log("error api user", e)})
    },[])

    const _login = (e) => {
        e.preventDefault();
        console.log("entro Data", data);
        axios.post('/api/login', data)
        .then(res => {
            console.log("resLogin", res.data);
            setUser(res.data.user);
            setAuth(true);
            localStorage.setItem('access_token', res.data.token_type+' '+res.data.access_token);
            axios.defaults.headers.common['Authorization']=res.data.token_type+' '+res.data.access_token;
            navigate("/admin/usuarios")
        }).catch(e => {
            console.log("error loguin", e)
            let message = e.response.data.message;
            if(e.response.status === 401){
                message = "El usuario o contraseña son incorrectos, por favor verificalos."
            }
            M.toast({
                html:`<i class="material-icons">sentiment_very_dissatisfied</i> &nbsp;&nbsp;<span>${message}</span>`,
                classes:"error-notify"
            })
        })
    }
    
    return (
        <div className='bg-principal'>
            <div className='bg-transparencia'>
                <div className='bg-transparencia'>
                    <div className='cont-form-login'>
                        <div className='centrar'><img src='../../images/logo.png' alt='dento metric' /></div>
                        <div className=' m-p-0 alto80 centrar'>
                            <div className="row m-p-0 ancho100">
                                <form className="col s12" id='login-form' onSubmit={_login}>
                                    <div className="row m-p-0">
                                        <div className="input-field col s12">
                                            <input id="email" type="email" className="validate"  
                                            onChange={(e)=>setData({...data,email:e.target.value})}/>
                                            <label htmlFor="email">Email</label>
                                        </div>
                                    </div>
                                    <div className="row m-p-0">
                                        <div className="input-field col s12">
                                            <input id="text" type="password"  className="validate" 
                                            onChange={(e)=>setData({...data,password:e.target.value})}/>
                                            <label htmlFor="password">Password</label>
                                        </div>
                                    </div>
                                    <div style={{textAlign:"right"}}> 
                                        <button className="btn waves-effect waves-light" type="submit" name="action">Submit
                                            <i className="material-icons right">send</i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;